import Vue from 'vue';
import VJsf from '@koumoul/vjsf';
import {
  VTooltip,
  VIcon,
  VListItem,
  VAvatar,
  VFlex,
  VLayout,
  VSelect,
  VTextarea,
  VCheckbox,
  VTextField,
  VCombobox,
  VChip,
  VListItemTitle,
  VListItemContent,
  VRadio,
  VRadioGroup,
  VCol,
  VRow,
} from 'vuetify/lib';

Vue.component('VTooltip', VTooltip);
Vue.component('VIcon', VIcon);
Vue.component('VListItem', VListItem);
Vue.component('VListItemTitle', VListItemTitle);
Vue.component('VListItemContent', VListItemContent);
Vue.component('VAvatar', VAvatar);
Vue.component('VFlex', VFlex);
Vue.component('VLayout', VLayout);
Vue.component('VSelect', VSelect);
Vue.component('VTextarea', VTextarea);
Vue.component('VCheckbox', VCheckbox);
Vue.component('VTextField', VTextField);
Vue.component('VCombobox', VCombobox);
Vue.component('VChip', VChip);
Vue.component('VRadio', VRadio);
Vue.component('VRadioGroup', VRadioGroup);
Vue.component('VCol', VCol);
Vue.component('VRow', VRow);

Vue.component('VJsf', VJsf.default);
