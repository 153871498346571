import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class PropertyEnvironments extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'PropertyEnvironments';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

const servicePath = 'property-environments';

const servicePlugin = makeServicePlugin({
  Model: PropertyEnvironments,
  service: feathersClient.service(servicePath),
  servicePath,
});

export const schema = {
  type: 'object',
  required: ['name', 'environmentTypeId', 'propertyEcosystemId'],
  properties: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    environmentTypeId: {
      type: 'string',
      title: 'Environment Type',
    },
    propertyId: {
      type: 'string',
      'x-display': 'hidden',
    },
    propertyEcosystemId: {
      type: 'string',
      title: 'Ecosystem',
    },
    rank: {
      type: 'integer',
      'x-display': 'hidden',
      default: 1,
    },
    dataDestinationExtKey: {
      type: 'string',
      title: 'Data Destination Key (e.g. RSID for Adobe Analytics)',
    },
    dataDestinationExtName: {
      type: 'string',
      title:
        'Data Destination Name (e.g. Report Suite Name for Adobe Analytics)',
      'x-display': 'hidden',
    },
    dataDestingationExtConfig: {
      type: 'string',
      title: 'External Config',
      'x-display': 'hidden',
    },
  },
};

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
