import store from '@/stores';
import { PropertySetupStatus } from '@/lib/properties.enum';

/**
 * Check if the property creation has not been completed and redirect the user to the flow
 */
const checkPropertyStatus = async (to, from, next) => {
  const propertyId = to.params.id;

  const property = await store.dispatch('properties/get', [
    propertyId,
    {
      query: {
        $association: 'defaultProperties',
      },
    },
  ]);

  if (property.propertySetupStatus === PropertySetupStatus.InProgress) {
    return next({
      name: 'properties.created.step1',
      params: {
        id: propertyId,
      },
      replace: true,
    });
  }

  return next();
};

export default checkPropertyStatus;
