import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class Agencies extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Agencies';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

const servicePath = 'agencies';

const servicePlugin = makeServicePlugin({
  Model: Agencies,
  service: feathersClient.service(servicePath),
  servicePath,
  // TODO: Clean this up to use existing feathers-vuex
  // state/getters/mutations/actions instead of using agencies/loadAgencies
  state: {
    agencies: [],
    selectedAgency: null,
  },
  getters: {
    agencies: state => state.agencies,
    selectedAgency: state => state.selectedAgency,
    selectedAgencyId: state => state.selectedAgency?.id,
  },
  mutations: {
    selectAgency(state, { agencyId }) {
      state.selectedAgency = state.agencies.find(
        agency => agency.id === agencyId,
      );
    },
    loadAgencies(state, { agencies }) {
      if (!Array.isArray(agencies)) return;

      if (!state.selectedAgency) {
        const [firstAgency] = agencies;
        const selectedAgencyId = window.sessionStorage.selectedAgency || null;
        const selectAgency = selectedAgencyId
          ? agencies.find(agency => agency.id === selectedAgencyId)
          : firstAgency;
        state.selectedAgency = selectAgency;
      }

      state.agencies = agencies;
    },
  },
  actions: {
    selectAgency({ commit }, agencyId) {
      commit('selectAgency', { agencyId });
    },
    loadAgencies({ commit }, agencies) {
      commit('loadAgencies', { agencies });
    },
  },
});

export function getAllAgencies(
  findAgencies = () => {
    //
  },
  userData = {
    findAgencyUser: () => {
      //
    },
    userId: null,
  },
) {
  const { userId, findAgencyUser } = userData;

  if (userId) {
    return findAgencyUser({
      query: {
        userId,
        $limit: -1,
      },
    }).then(agencyUsers => agencyUsers.map(agencyUser => agencyUser.agency));
  }

  return findAgencies({
    query: userId ? { $limit: -1, userId } : { $limit: -1 },
  });
}

export const schema = {
  type: 'object',
  agencies: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    name: {
      type: 'string',
      title: 'Agency Name',
    },
    code: {
      type: 'string',
      title: 'Code',
    },
    createdAt: {
      type: 'string',
      title: 'Created at',
      'x-display': 'hidden',
    },
    updatedAt: {
      type: 'string',
      title: 'Updated at',
      'x-display': 'hidden',
    },
    deletedAt: {
      type: 'string',
      title: 'Deleted at',
      'x-display': 'hidden',
    },
  },
};

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
