export enum PermissionTypes {
  Property = 'property',
  Organization = 'organization',
}

export enum PermissionCodes {
  ViewMapExistingImplementation = 'view_map_existing_implementation',
  ManageMapExistingImplementation = 'manage_map_existing_implementation',
  UaEvents = 'ua_events',
  CustomTriggersInMei = 'custom_triggers_mei',
  CustomVariablesInMei = 'custom_variables_mei',
  CustomDimensionsAndMetrics = 'custom_dimenstion_and_metrics',
  EnhancedEcommerce = 'enhanced_ecommerce',
  ClickTracking = 'click_tracking',
  ManageCustomRecordsInMEI = 'manage_custom_records_mei',
  ViewBusinessRequirements = 'view_business_requirements',
  ManageBusinessRequirements = 'manage_business_requirements',
  ViewAssignVariables = 'view_assign_variables',
  ManageAssignVariables = 'manage_assign_variables',
  ViewEvents = 'view_events',
  ManageEvents = 'manage_events',
  ViewAttributes = 'view_attributes',
  ManageAttributes = 'manage_attributes',
  ViewDataSources = 'view_data_sources',
  ManageTests = 'manage_tests',
  ManageCustomRecords = 'manage_custom_records',
  ViewDocumentation = 'view_documentation',
  ManageNotes = 'manage_notes',
  DownloadDocumentation = 'download_documentation',
  ViewDataDestination = 'view_data_destination',
  DownloadSdrReport = 'download_sdr_report',
  DownloadTestingReport = 'download_testing_report',
  ViewVisualSdr = 'view_visual_sdr',
  ViewBuilds = 'view_builds',
  ManageBuilds = 'manage_builds',
  TagManagerDeploy = 'tag_manager_deploy',
  DataDestinationDeploy = 'data_destination_deploy',
  DataLayerDeploy = 'data_layer_deploy',
  GaBasicMigrateDeploy = 'ga_basic_migrate_deploy',
  ViewCalculatedMetrics = 'view_calculated_metrics',
  ManageCalculatedMetrics = 'manage_calculated_metrics',
  ViewDashboards = 'view_dashboards',
  ManageDashboards = 'manage_dashboards',
  ManageBusinessUser = 'manage_business_user',
  ManagePropertySettings = 'manage_property_settings',
  ManagePropertyCustomComponents = 'manage_property_custom_components',
}

export const DesignTabPermissions = [
  PermissionCodes.ViewBusinessRequirements,
  PermissionCodes.ManageBusinessRequirements,
  PermissionCodes.ViewAssignVariables,
  PermissionCodes.ManageAssignVariables,
  PermissionCodes.ViewEvents,
  PermissionCodes.ManageEvents,
  PermissionCodes.ViewAttributes,
  PermissionCodes.ManageAttributes,
  PermissionCodes.ViewDataSources,
  PermissionCodes.ManageTests,
  PermissionCodes.ManageCustomRecords,
];

export const DocumentTabPermissions = [
  PermissionCodes.ViewDocumentation,
  PermissionCodes.ManageNotes,
  PermissionCodes.ViewDataDestination,
  PermissionCodes.DownloadDocumentation,
  PermissionCodes.DownloadSdrReport,
  PermissionCodes.DownloadTestingReport,
  PermissionCodes.ViewVisualSdr,
];

export const DeployTabPermissions = [
  PermissionCodes.ViewBuilds,
  PermissionCodes.ManageBuilds,
  PermissionCodes.TagManagerDeploy,
  PermissionCodes.DataDestinationDeploy,
  PermissionCodes.DataLayerDeploy,
  PermissionCodes.GaBasicMigrateDeploy,
  PermissionCodes.ViewCalculatedMetrics,
  PermissionCodes.ManageCalculatedMetrics,
  PermissionCodes.ViewDashboards,
  PermissionCodes.ManageDashboards,
];

export const ActTabPermissions = [PermissionCodes.ManageBusinessUser];

export const PropertySettingsPermissions = [
  PermissionCodes.ManagePropertySettings,
  PermissionCodes.ManagePropertyCustomComponents,
];

export const MapExistingImplementationPermissions = [
  PermissionCodes.ViewMapExistingImplementation,
  PermissionCodes.ManageMapExistingImplementation,
  PermissionCodes.UaEvents,
  PermissionCodes.CustomTriggersInMei,
  PermissionCodes.CustomVariablesInMei,
  PermissionCodes.CustomDimensionsAndMetrics,
  PermissionCodes.EnhancedEcommerce,
  PermissionCodes.ClickTracking,
  PermissionCodes.ManageCustomRecordsInMEI,
];
