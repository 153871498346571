export default {
  namespaced: true,

  state: {
    totalSteps: 1,
  },

  getters: {
    getTotalSteps: state => state.totalSteps,
  },

  mutations: {
    setSteps(state, { steps }) {
      state.totalSteps = steps;
    },
  },

  actions: {
    setTotalSteps({ commit }, { steps }) {
      commit('setSteps', { steps });
    },

    resetSteps({ commit }) {
      commit('setSteps', { steps: 1 });
    },
  },
};
