import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class EventTagMaps extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'EventTagMaps';

  // Define default properties here
  static instanceDefaults() {
    return {
      name: '',
      description: '',
      eventId: null,
    };
  }

  // Used for instance specific transforms
  // static setupInstance() {}
}

const servicePath = 'event-tag-maps';

const servicePlugin = makeServicePlugin({
  Model: EventTagMaps,
  service: feathersClient.service(servicePath),
  servicePath,
  addOnUpsert: true,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
