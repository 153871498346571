export enum PropertyAvailabilityStatus {
  Available = 'available',
  MapInProgress = 'map_in_progress',
}

export enum PropertySetupStatus {
  Complete = 'complete',
  InProgress = 'in_progress',
  Legacy = 'legacy',
  MapExistingInProgress = 'map_existing_implementation_in_progress',
  LegacyMapExistingInProgress = 'map_existing_implementation_in_progress_legacy',
}

export enum DataLayerNames {
  Adobe = 'appEventData',
  Google = 'dataLayer',
}
