import Vue from 'vue';
import VueCompositionApi from '@vue/composition-api';
// @ts-ignore
import Layout from '@/components/Layout';
import emotion from '@/config/emotion';
import highlightjs from '@/config/highlightjs';
import router from '@/config/routes';
import vuetify from '@/config/vuetify';
import themes from '@/config/themes/searchdiscovery';
import store from '@/stores';
import { setAxiosRequestHeaders } from '@/lib/networking';
import i18n from '@/config/i18n';
import setupFilters from '@/config/filters';
import '@koumoul/vjsf/dist/main.css';
import '@/styles/scss/app.scss';
import { extend } from 'vee-validate';
import { globalRules, extendRules } from './lib/validationRules';
import PortalVue from 'portal-vue';

// makes some rules global so they can be reused in components without using extend everytime
extendRules(extend, globalRules);

require('./config/vsjf');

setAxiosRequestHeaders(store);

Vue.config.productionTip = false;

(Vue as Partial<Vue>).router = Vue.observable(router);

Vue.use(VueCompositionApi);
Vue.use(PortalVue);

setupFilters();

// Vue.config.errorHandler = (err: Error, vm: Vue, trace: string): void => {
//   logger.error(err.message, {
//     trace,
//     vm,
//   });
// };

// Vue.config.warnHandler = (err: string, vm: Vue, trace: string): void => {
//   logger.info(err, {
//     trace,
//     vm,
//   });
// };

new Vue({
  emotion,
  highlightjs,
  i18n,
  router,
  store,
  vuetify,
  provide() {
    return {
      get themeConfig() {
        return themes.light;
      },
    };
  },
  render: createElement => createElement(Layout),
}).$mount('#app');
