import Axios from 'axios';
import { APOLLO_STORAGE_KEY } from './constants';

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3030',
});

axios.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem(APOLLO_STORAGE_KEY);

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },

  error => Promise.reject(error),
);

axios.interceptors.response.use(
  response => response,
  async error => Promise.reject(error),
);

export default axios;
