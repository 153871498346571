const fetchMemberRepos = async (
  fetched,
  token,
  pageSize = 10,
  pageNumber = 1,
) => {
  const repos = await fetch(
    `https://api.github.com/user/repos?type=member&page=${pageNumber}&per_page=${pageSize}`,
    {
      method: 'get',
      headers: new Headers({
        Authorization: `token ${token}`,
      }),
    },
  ).then(response => response.json());
  fetched.push(...repos);
  if (repos.length >= pageSize) {
    return fetchMemberRepos(fetched, token, pageSize, pageNumber + 1);
  }
  return fetched;
};

export default {
  namespaced: true,
  state: {
    githubOrg: {
      visibility: false,
      loading: true,
      organizations: [],
      repositories: [],
      saveBtnLoading: false,
    },
  },
  getters: {
    githubOrg: state => state.githubOrg,
  },
  mutations: {
    setVisibility(state) {
      state.githubOrg = { ...state.githubOrg, visibility: true };
    },
    setGithubOrg(state, { githubOrgList }) {
      state.githubOrg = {
        ...state.githubOrg,
        organizations: githubOrgList?.map(item => ({
          name: item.login,
          id: item.login,
        })),
      };
    },
    setGithubMemberRepos(state, { githubRepoList }) {
      state.githubOrg = {
        ...state.githubOrg,
        organizations: [
          ...state.githubOrg.organizations,
          ...githubRepoList
            ?.filter(item => item.owner?.type === 'Organization')
            .map(item => ({
              name: item.owner.login,
              id: item.owner.login,
            })),
        ],
        loading: false,
      };
    },
  },
  actions: {
    async openGithubOrg({ commit }, { item }) {
      commit('setVisibility');
      await fetch(`https://api.github.com/user/orgs`, {
        method: 'get',
        headers: new Headers({
          Authorization: `token ${item.githubToken}`,
        }),
      })
        .then(response => response.json())
        .then(json => {
          commit('setGithubOrg', { githubOrgList: json });
        })
        .catch(error => {
          throw new Error(error);
        });
      await fetchMemberRepos([], item.githubToken)
        .then(json => {
          commit('setGithubMemberRepos', { githubRepoList: json });
        })
        .catch(error => {
          throw new Error(error);
        });
    },
  },
};
