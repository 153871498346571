<template lang="pug">
v-app-bar.header.pa-0.pr-4.elevation-0.gradient-background(
  app
  clipped-left
  fixed
)
  v-container.pa-0(fluid)
    .d-flex.justify-space-between.align-center
      .header__content.d-flex.align-center
        .logo
          router-link(to='/')

        router-link.pl-10.text-decoration-none(
          :to='{ name: "properties" }'
          v-if='isAuthenticated && isSelectedProperty'
        )
          v-icon(
            color='primary'
            small
          ) fa-chevron-left

        .property-name.pl-10(v-if='isAuthenticated && isSelectedProperty && property')
          span.text--label {{ $t('Property: ') }}
          v-tooltip(bottom)
            template(v-slot:activator='{ on, attrs }')
              span.ml-2(
                v-bind='attrs'
                v-on='on'
              ) {{ propertyName }}
            span {{ property.name }}

        v-btn.ml-3(
          :ripple='false'
          @click='onPropertySettingsClicked'
          color='variable'
          data-qa='button-property-settings'
          elevation='0'
          fab='fab'
          small
          v-if='showPropertySettingsButton'
        )
          v-icon fa-cog

        .organizations.pl-4(v-if='isAuthenticated && !!organizations.length')
          v-select.apollo-select-label.apollo-select-label--secondary.apollo-select-small(
            :items='organizations'
            :label='$t("Organizations")'
            :menu-props='{ bottom: true, offsetY: true }'
            :value='selectedOrganizationId'
            @change='onOrganizationChange'
            append-icon='fa-chevron-down'
            data-qa='header-organizations-dropdown'
            hide-details='auto'
            item-text='name'
            item-value='id'
          )

        .agencies.pl-4(v-if='isAuthenticated && agencies.length > 1')
          v-select.apollo-select-label.apollo-select-label--secondary.apollo-select-small(
            :items='agencies'
            :label='$t("Agencies")'
            :menu-props='{ bottom: true, offsetY: true }'
            :value='selectedAgencyId'
            @change='onAgencyChange'
            append-icon='fa-chevron-down'
            data-qa='header-agencies-dropdown'
            hide-details='auto'
            item-text='name'
            item-value='id'
          )

      .header__aside.d-flex.align-center.justify-end
        .avatar.mr-10.d-flex.align-center(v-if='isAuthenticated && personName')
          v-avatar(
            color='variable'
            size='40'
          )
            span.white--text.subtitle-2 {{ personInitials }}
          div
            h4.avatar__title.mb-0.ml-2 {{ personName }}

        .main-menu.ml-8(v-if='isAuthenticated')
          v-menu(
            bottom
            left
            z-index='9999'
          )
            template(v-slot:activator='{ on }')
              v-btn(
                color='variable'
                data-qa='header-waffle-menu-btn'
                elevation='0'
                fab
                small
                v-on='on'
              )
                v-icon.menu-icon far fa-th
            v-list
              v-list-item-group(color='primary')
                v-list-item(
                  :data-qa='`header-waffle-menu-${item.title}`'
                  :href='item.href'
                  :key='item.title'
                  :ripple='false'
                  :target='item.href ? "_blank" : null'
                  :to='item.to'
                  @click='item.click ? item.click() : null'
                  v-for='item in menuItems'
                  v-if='item.visible'
                )
                  .v-list-item__icon.menu-icon
                    v-icon {{ item.icon }}
                  .v-list-item__content
                    .v-list-item__title
                      | {{ item.title }}
</template>

<script>
import { computed } from '@vue/composition-api';
import { hasAccess } from '@/lib/hasAccess';
import { PermissionCodes } from '@/lib/permissions';
import { PermissionTypes } from '../../lib/permissions';

export default {
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    agencies: {
      type: Array,
      default: () => [],
    },
    selectedAgencyId: {
      type: [String, Number],
      default: null,
    },
    organizations: {
      type: Array,
      default: () => [],
    },
    selectedOrganizationId: {
      type: [String, Number],
      default: null,
    },
    property: {
      type: Object,
      default: () => ({}),
    },
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
    isSelectedProperty: {
      type: Boolean,
      default: false,
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    isSystemAdmin: {
      type: Boolean,
      default: false,
    },
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
    isOrganizationAdmin: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const personName = computed(() =>
      props.user?.firstName
        ? `${props.user?.firstName} ${props.user?.lastName}`
        : '',
    );

    const personInitials = computed(() =>
      props.user?.firstName
        ? `${props.user?.firstName[0]}${props.user?.lastName[0]}`
        : '??',
    );

    const propertyName = computed(() =>
      props.property?.name.length > 30
        ? `${props.property?.name.substring(0, 30)}...`
        : props.property?.name,
    );

    const onAgencyChange = agencyId => emit('agencyChanged', agencyId);

    const onOrganizationChange = organizationId =>
      emit('organizationChanged', organizationId);

    const showPropertySettingsButton = computed(
      () =>
        props.isAuthenticated &&
        props.isSelectedProperty &&
        hasAccess(
          PermissionCodes.ManagePropertySettings,
          PermissionTypes.Property,
          props.property?.id,
        ),
    );

    const onPropertySettingsClicked = () =>
      emit('propertySettingsClicked', props.property.id);

    return {
      onAgencyChange,
      onOrganizationChange,
      onPropertySettingsClicked,
      personInitials,
      personName,
      propertyName,
      showPropertySettingsButton,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  z-index: 6;
}

.v-btn:not(.v-btn--round).v-size--default {
  font-weight: 500;
  font-size: 12px;
  height: auto;
  align-self: center;
}

.logo a {
  display: inline-block;
  width: 64px;
  height: 64px;
  background: url('./../../../public/images/logo.svg') no-repeat center center;
}

.header {
  font-size: 12px;
  font-weight: 500;
}

.header::v-deep .v-toolbar__content {
  padding: 0;
}

.property-name {
  color: white;
}

.avatar::v-deep .avatar__title {
  color: white;
  font-weight: 500;
}

.v-badge::v-deep .v-btn.v-btn--icon.v-size--default {
  width: auto;
  height: auto;
  font-size: 10px;
}

.v-select::v-deep .v-select__selections {
  display: block;
}

.main-menu::v-deep .v-icon {
  margin-top: 2px;
}

::v-deep .v-text-field.apollo-select-label .v-label {
  margin-top: 2px !important;
}
</style>
