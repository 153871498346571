export const APOLLO_STORAGE_KEY =
  process.env.VUE_APOLLO_STORAGE_KEY || 'sdi-apollo-token';

export const FADE_TIMEOUT = 4000;

// Property Setup Statuses
export const MAP_EXISTING_IMPLEMENTATION_IN_PROGRESS =
  'map_existing_implementation_in_progress';
export const MAP_EXISTING_IMPLEMENTATION_IN_PROGRESS_LEGACY =
  'map_existing_implementation_in_progress_legacy';
export const IN_PROGRESS = 'in_progress';
export const COMPLETE = 'complete';
