import { resolvePropertySteps } from '@/lib/resolve-property-steps';
import store from '@/stores';

const fetchProperty = async propertyId =>
  store.dispatch('properties/get', [
    propertyId,
    {
      query: {
        $association: 'defaultProperties',
      },
    },
  ]);

const fetchPropertyEcosystemConnection = async (
  propertyEcosystemId,
  userId,
) => {
  const response = await store.dispatch('property-ecosystem-connections/find', {
    query: {
      userId,
      propertyEcosystemId,
      $limit: 1,
    },
  });

  return response.data?.[0];
};

/**
 * Fetch the details of a property that we'll use for populating the Property Creation flow
 */
const resolveProperty = async (to, from, next) => {
  const user = store.getters['auth/user'];
  const propertyId = to.params.id;

  const property = await fetchProperty(propertyId);

  const steps = resolvePropertySteps(
    property.propertyPlan.code,
    property.propertyEcosystems[0].ecosystem.code,
    property.organization.isSandbox,
  );

  if (steps) await store.dispatch('stepper/setTotalSteps', { steps });

  const propertyEcosystemId = property?.propertyEcosystems?.[0].id;

  const propertyEcosystemConnection = await fetchPropertyEcosystemConnection(
    propertyEcosystemId,
    user.id,
  );

  to.meta.property = property;
  to.meta.propertyEcosystemConnection = propertyEcosystemConnection;

  return next();
};

export default resolveProperty;
