import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';
import emitter from '@/lib/emitter';

class BuildDeployments extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'BuildDeployments';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

const servicePath = 'build-deployments';

const servicePlugin = makeServicePlugin({
  Model: BuildDeployments,
  service: feathersClient.service(servicePath),
  servicePath,
  multi: ['create'],
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

feathersClient
  .service(servicePath)
  .on('gtm-deployment', payload => emitter.emit('gtm-deployment', payload));

feathersClient
  .service(servicePath)
  .on('launch-deployment', payload =>
    emitter.emit('launch-deployment', payload),
  );

export default servicePlugin;
