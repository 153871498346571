import feathersClient, { makeAuthPlugin } from '@/config/feathers';
import { SystemRoles, OrganizationRoles, AgencyRoles } from '@/lib/roles';
import { initializeFeatureFlags } from '@/config/features';
import { APOLLO_STORAGE_KEY } from '../config/constants';
import uniqBy from 'lodash/uniqBy';

const servicePath = 'authentication';

const localStorageKeys = {
  selectedOrganization: 'selectedOrganization',
  selectedAgency: 'selectedAgency',
  currentUserId: 'currentUserId',
};

export default makeAuthPlugin({
  userService: 'users',

  state: {
    userOrganizations: [],
    userAgencies: [],
    userPermissions: {},
    selectedOrganizationId:
      window.localStorage.getItem(localStorageKeys.selectedOrganization) ||
      null,
    selectedAgencyId:
      window.localStorage.getItem(localStorageKeys.selectedAgency) || null,
    currentUserId:
      window.localStorage.getItem(localStorageKeys.currentUserId) || null,
  },

  getters: {
    userPermissions: state => state.userPermissions,
    selectedOrganizationId: state => state.selectedOrganizationId,
    selectedAgencyId: state => state.selectedAgencyId,

    userOrganizations: state => {
      if (!state.user || !state.user.id) {
        return [];
      }

      return state.userOrganizations.map(org => ({
        ...org.organization,
        role: org.roles[0],
      }));
    },

    currentUserId: (state, getters) =>
      state.user.id ? state.user.id : getters.user.id,

    selectedOrganization: (state, getters) => {
      if (!state.selectedOrganizationId) {
        return null;
      }

      return getters.userOrganizations.find(
        organization =>
          String(organization.id) === String(state.selectedOrganizationId),
      );
    },

    userAgencies: state => {
      if (!state.user || !state.user.id) {
        return [];
      }

      const agencies = state.userOrganizations.map(orgUser => ({
        ...orgUser.organization.agency,
        role: orgUser.organization.agency?.agencyUsers
          ? orgUser.organization.agency?.agencyUsers[0]?.roles[0]
          : null,
      }));

      return uniqBy(agencies, 'name');
    },

    haveOrganizations: (state, getters) => !!getters.userOrganizations?.length,

    isSystemAdmin: state => state.user?.roles.includes(SystemRoles.Admin),

    isOrganizationAdmin: (state, getters) =>
      !!getters.userOrganizations?.find(
        organization => organization.role === OrganizationRoles.Admin,
      ),

    isOrganizationArchitect: (state, getters) =>
      !!getters.userOrganizations?.find(
        organization => organization.role === OrganizationRoles.Architect,
      ),

    isAgencyAdmin: state =>
      !!state.userAgencies?.find(agency => agency.role === AgencyRoles.Admin),

    isAgencyArchitect: state =>
      !!state.userAgencies?.find(
        agency => agency.role === AgencyRoles.Architect,
      ),

    haveRoleInOrganization: (state, getters) => (organizationId, role) => {
      const roles = Array.isArray(role) ? role : [role];

      const organization = getters.userOrganizations?.find(
        organization => organization.id === organizationId,
      );

      if (!organization) {
        return false;
      }

      return roles
        .map(r => r.toLowerCase())
        .includes(organization.role.toLowerCase());
    },

    haveRoleInAgency: (state, getters) => (agencyId, role) => {
      const roles = Array.isArray(role) ? role : [role];

      const agency = state.userAgencies?.find(agency => agency.id === agencyId);

      if (!agency) {
        return false;
      }

      return roles
        .map(r => r.toLowerCase())
        .includes(agency?.role?.toLowerCase());
    },
  },

  mutations: {
    setUserPermissions(state, { userPermissions }) {
      state.userPermissions = userPermissions;
    },
    setUserOrganizations(state, { userOrganizations }) {
      state.userOrganizations = userOrganizations;
    },

    setUserAgencies(state, { userAgencies }) {
      if (!userAgencies) {
        return;
      }

      const agencies = userAgencies.map(userAgency => ({
        ...userAgency.agency,
        role: userAgency.roles[0],
      }));

      state.userAgencies = agencies;
    },

    setSelectedOrganization(state, { organizationId }) {
      state.selectedOrganizationId = organizationId;

      window.localStorage.setItem(
        localStorageKeys.selectedOrganization,
        organizationId,
      );
    },

    setSelectedAgency(state, { agencyId }) {
      if (agencyId) {
        state.selectedAgencyId = agencyId;

        window.localStorage.setItem(localStorageKeys.selectedAgency, agencyId);
      }
    },

    setCurrentUser(state, { currentUserId }) {
      if (currentUserId) {
        state.currentUserId = currentUserId;

        window.localStorage.setItem(
          localStorageKeys.currentUserId,
          currentUserId,
        );
      }
    },
  },

  actions: {
    customLogout: async ({ dispatch }) => {
      google_idp.auth.signOut();
      await dispatch('logout');

      // Reloading the window per https://vuex.feathersjs.com/common-patterns.html#clearing-data-upon-user-logout
      window.location = '/';
    },

    logoutWithoutRedirect: async ({ dispatch }) => {
      await dispatch('logout');
      window.localStorage.removeItem(APOLLO_STORAGE_KEY);
    },

    loadUserPermissions: async ({ dispatch, commit, state, getters }) => {
      if (!state.user || !state.user.id) {
        return;
      }

      const userId = getters.user.id;

      const userPermissions =
        (await dispatch(
          'user-permissions/find',
          {
            query: {
              userId,
              $association: 'includePermissions',
              $limit: -1,
            },
            paginate: false,
          },
          { root: true },
        ).catch(() => {})) || [];

      /* This creates a map for each permission code there is an object with subject ids as keys
        this way you can check access for subject directly by key:
          if(permissions['property']['manage_business_requirements']['<propertyId>'])
          if(permissions['organization']['map_existing_implementation']['<organizationId>'])
      */
      const permissions = { property: {}, organization: {} };
      userPermissions.forEach(up => {
        const code = up.permission.code;
        const type = up.permission.type;
        permissions[type][code] = {};
        up.subjectIds.forEach(subjectId => {
          permissions[type][code][subjectId] = true;
        });
      });

      commit('setUserPermissions', {
        userPermissions: JSON.parse(JSON.stringify(permissions)),
      });
    },
    loadOrganizationsAndAgencies: async (
      { dispatch, commit, state, getters },
      data,
    ) => {
      if (!state.user || !state.user.id) {
        return;
      }

      // Check if user is different from last login
      // If so, remove the selected  org and agency values
      if (
        window.localStorage.getItem(localStorageKeys.currentUserId) !==
        state.user.id
      ) {
        window.localStorage.removeItem(localStorageKeys.selectedAgency);
        window.localStorage.removeItem(localStorageKeys.selectedOrganization);
        state.selectedOrganizationId = null;
      }

      window.localStorage.setItem(
        localStorageKeys.currentUserId,
        getters.user.id,
      );

      const userId = getters.user.id;

      // Fetch the organizations that the user is part of
      // and include their agencies but only if the user is part of them
      const userOrganizations =
        (await dispatch(
          'organization-users/find',
          {
            query: {
              userId,
              $association: 'loadOrganizationsAndAgencies',
              $associationArgs: {
                userId,
              },
              $limit: -1,
            },
            paginate: false,
          },
          { root: true },
        ).catch(() => {})) || [];

      const isThereOrganizationWithoutAgency = userOrganizations.some(
        userOrganization => !userOrganization.organization.agency,
      );

      if (isThereOrganizationWithoutAgency) {
        const organizations =
          (await dispatch(
            'organizations/find',
            {
              query: {
                id: {
                  $in: userOrganizations
                    .filter(
                      userOrganization => !userOrganization.organization.agency,
                    )
                    .map(userOrganization => userOrganization.organizationId),
                },
                $association: 'includeAgency',
                $limit: -1,
              },
              paginate: false,
            },
            { root: true },
          ).catch(() => {})) || [];

        userOrganizations
          .filter(el => !el.organization.agency)
          .forEach(userOrganization => {
            userOrganization.organization.agency = organizations.find(
              organization =>
                organization.agencyId ===
                userOrganization.organization.agencyId,
            )?.agency;
          });
      }

      if (userOrganizations) {
        const organizationsWithAgencies = userOrganizations.filter(
          el => el.organization.agency,
        );
        commit('setUserOrganizations', {
          userOrganizations: JSON.parse(
            JSON.stringify(organizationsWithAgencies),
          ),
        });

        if (data?.propertyId) {
          const property =
            (await dispatch(
              'properties/get',
              [
                data?.propertyId,
                {
                  query: {
                    $association: 'defaultProperties',
                  },
                },
              ],
              { root: true },
            ).catch(() => {})) || {};
          const targetOrganization =
            getters.userOrganizations?.find(
              userOrganization =>
                userOrganization?.id === property?.organizationId,
            ) ||
            getters.userOrganizations?.[0] ||
            null;
          commit('setSelectedOrganization', {
            organizationId: targetOrganization?.id,
          });

          commit('setSelectedAgency', {
            agencyId: targetOrganization?.agency?.id,
          });
        } else if (!state.selectedOrganizationId) {
          commit('setSelectedOrganization', {
            organizationId: getters.userOrganizations?.[0]?.id || null,
          });

          commit('setSelectedAgency', {
            agencyId: getters.userOrganizations?.[0]?.agency?.id || null,
          });
        }
      }

      // Fetch all agencies that the user is part of
      // NOTE: User might be part of an agency, but not in organization
      const userAgencies = await dispatch(
        'agency-users/find',
        {
          query: {
            userId,
            $limit: -1,
            $association: 'includeAgencyAndUser',
          },
        },
        { root: true },
      ).catch(() => {});

      if (userAgencies) {
        commit('setUserAgencies', {
          userAgencies: JSON.parse(JSON.stringify(userAgencies)),
        });
      }
    },

    setSelectedOrganization: ({ commit }, organizationId) => {
      commit('setSelectedOrganization', { organizationId });

      window.location = '/';
    },

    setSelectedAgency: ({ commit, getters }, agencyId) => {
      const organization = getters.userOrganizations.find(
        org => String(org.agencyId) === String(agencyId),
      );

      commit('setSelectedAgency', { agencyId });
      commit('setSelectedOrganization', { organizationId: organization.id });

      window.location = '/';
    },
  },
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [initializeFeatureFlags],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});
