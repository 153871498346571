import i18n from '@/config/i18n';
import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';
import { AdobeImplementations } from '@/lib/implementations.enum';
import { EcosystemCodes } from '@/lib/ecosystems.enum';

class PropertyEcosystems extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'PropertyEcosystems';

  get name() {
    if (
      !this.ecosystem?.tagManager?.name ||
      !this.ecosystem?.dataDestination?.name
    )
      return i18n.t('Associations missing');

    return `${this.ecosystem.tagManager.name} / ${this.ecosystem.dataDestination.name}`;
  }

  static setupInstance(data, { models }) {
    if (data.propertyTagManager) {
      data.dataLayerName = data.propertyTagManager.dataLayerName;
    }

    if (
      data.ecosystem &&
      data.ecosystem.tagManager &&
      data.ecosystem.dataDestination
    ) {
      data.ecosystemCode = `${data.ecosystem.tagManager.code}_${data.ecosystem.dataDestination.code}`;
    }

    return data;
  }
}

const servicePath = 'property-ecosystems';

const servicePlugin = makeServicePlugin({
  Model: PropertyEcosystems,
  service: feathersClient.service(servicePath),
  servicePath,
  namespace: 'property-ecosystems',
});

export const schema = {
  type: 'object',
  name: 'property-ecosystems',
  customValidation: true,
  required: ['ecosystemId', 'dataLayerName'],
  properties: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    dataLayerName: {
      type: 'string',
      'x-display': 'hidden',
      title: 'Data layer',
    },
    propertyId: {
      type: 'string',
      'x-display': 'hidden',
    },
    ecosystemId: {
      type: 'string',
      title: 'Ecosystem',
    },
    implementationType: {
      type: 'string',
      'x-display': 'hidden',
      title: i18n.t('Implementation Type'),
    },
    propertyTagManager: {
      type: 'object',
      'x-display': 'hidden',
    },
    propertyTagManagerId: {
      type: 'string',
      'x-display': 'hidden',
    },
  },
};

export const ecosystemsImplementations = [
  {
    name: 'Adobe Launch',
    code: EcosystemCodes.AdobeAnalytics,
    implementationTypes: [
      {
        name: i18n.t('Adobe Analytics (AppMeasurement)'),
        description: i18n.t('Send Data Directly to Adobe Analytics'),
        value: AdobeImplementations.Legacy,
      },
      {
        name: i18n.t('Adobe Analytics (WebSDK)'),
        description: i18n.t('Use Adobe XDM to populate Adobe Analytics'),
        value: AdobeImplementations.AA_AEP,
      },
      // TODO: Uncomment this object when we get back to the Data Lake ecosystem
      // {
      //   name: i18n.t('Data Lake (WebSDK)'),
      //   description: i18n.t('Send XDM to the Adobe data lake'),
      //   value: AdobeImplementations.Modern,
      // },
    ],
  },
  {
    name: 'Google Tag Manager',
    code: EcosystemCodes.GoogleTagManager,
    implementationTypes: [
      {
        name: i18n.t('Google Analytics 4'),
        description: i18n.t('Send data to a GA4 property'),
        value: 'client_ga4',
      },
    ],
  },
];

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
