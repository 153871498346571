import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class GoogleGTMVersions extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'GoogleGTMVersions';
}

const servicePath = 'google-gtm-versions';
const service = feathersClient.service(servicePath);

const servicePlugin = makeServicePlugin({
  Model: GoogleGTMVersions,
  service,
  servicePath,
  idField: 'containerVersionId',
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
