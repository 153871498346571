import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class PropertyEnvironmentTypes extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'PropertyEnvironmentTypes';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

const servicePath = 'property-environment-types';

const servicePlugin = makeServicePlugin({
  Model: PropertyEnvironmentTypes,
  service: feathersClient.service(servicePath),
  servicePath,
});

export const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    description: {
      type: 'string',
      title: 'Description',
    },
    allowMultiple: {
      type: 'boolean',
      title: 'Allow Multiple?',
      default: false,
    },
    defaultRank: {
      type: 'integer',
      title: 'Default Rank',
      default: 3,
    },
  },
};

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
