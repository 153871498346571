import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class AdminSettings extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'AdminSettings';
}

const servicePath = 'admin-settings';

const servicePlugin = makeServicePlugin({
  Model: AdminSettings,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
