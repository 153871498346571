import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class CustomizedRecordsStats extends BaseModel {
  static modelName = 'CustomizedRecordsStats';

  static instanceDefaults() {
    return {};
  }
}

const servicePath = 'customized-records-stats';
const service = feathersClient.service(servicePath);

const servicePlugin = makeServicePlugin({
  Model: CustomizedRecordsStats,
  service,
  servicePath,
  idField: 'organizationId',
});

service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
