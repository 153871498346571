import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import theme from './themes/searchdiscovery';

Vue.use(Vuetify);

// const themeQuery = window.matchMedia('(prefers-color-scheme: dark)');

export const options = {
  icons: {
    iconfont: 'fa',
  },
  theme: {
    // dark: themeQuery?.matches,
    themes: theme,
  },
};

const vuetify = new Vuetify(options);

// vuetify.framework.theme.dark = themeQuery?.matches;

// if (themeQuery) {
//   themeQuery.addListener(e => {
//     vuetify.framework.theme.dark = e.matches;
//   });
// }

export default vuetify;
