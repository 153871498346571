export enum SystemRoles {
  Admin = 'admin',
  User = 'user',
}

export enum AgencyRoles {
  Admin = 'admin',
  User = 'user',
  Architect = 'architect',
}

export enum OrganizationRoles {
  Admin = 'admin',
  User = 'user',
  Architect = 'architect',
}
