import axios from 'axios';
import { getCookie } from './utility';

const setAxiosRequestHeaders = store => {
  axios.interceptors.request.use(
    request => {
      const config = request;
      const token = getCookie('sdi-apollo-token-legacy');

      if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      config.withCredentials = true;

      return config;
    },
    error => Promise.reject(error),
  );

  axios.interceptors.response.use(
    response => response,
    async error => {
      if (error.response.status === 401) {
        await store.dispatch('auth/customLogout');
      }

      return Promise.reject(error);
    },
  );
};

export { setAxiosRequestHeaders };
export default setAxiosRequestHeaders;
