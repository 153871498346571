import { provide, inject } from '@vue/composition-api';

const StoreSymbol = Symbol('Vuex Store');

export function provideStore(store) {
  provide(StoreSymbol, store);
}

export function useStore() {
  const store = inject(StoreSymbol);

  if (!store) {
    return {};
  }

  return store;
}
