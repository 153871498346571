import { DataLayerEvents } from './dataLayerEvents.enum';
import { getReleaseVersion } from './getReleaseVersion';
import store from '@/stores';

export const populateDataLayer = (event, obj = null) => {
  switch (event) {
    case DataLayerEvents.PageLoadStarted:
      appEventData.push({
        event: 'Page Load Started',
        page: {
          pageCategory: obj.pageCategory || '',
          pageName: obj.pageName || '',
          propertyID: obj.propertyID || '',
          propertyPlan: obj.propertyPlan || '',
          releaseVersion: obj.releaseVersion || '',
        },
      });
      break;

    case DataLayerEvents.UserDetected:
      appEventData.push({
        event: 'User Detected',
        user: {
          agencyID: obj.agencyID || '',
          custKey: obj.custKey || '',
          organizationID: obj.organizationID || '',
          organizationPlan: obj.organizationPlan || '',
        },
      });
      break;

    case DataLayerEvents.PageLoadCompleted:
      appEventData.push({
        event: 'Page Load Completed',
      });
      break;

    case DataLayerEvents.DownloadLinkClicked:
      appEventData.push({
        event: 'Download Link Clicked',
        linkInfo: {
          fileType: obj.fileType || '',
          linkId: obj.linkId || '',
        },
      });
      break;

    case DataLayerEvents.ExitLinkClicked:
      appEventData.push({
        event: 'Exit Link Clicked',
        linkInfo: {
          linkId: obj.linkId || '',
        },
      });
      break;

    case DataLayerEvents.ErrorMessagePresented:
      appEventData.push({
        event: 'Error Message Presented',
        error: {
          errorCode: obj.errorCode || '',
          errorType: obj.errorType || '',
        },
      });
      break;
  }
};

/***
 * Page Load Started
 * User Detected
 * Page Load Completed
 */
export const dataLayerTracking = async (propertyId, page) => {
  const selectedOrganization = store.getters['auth/selectedOrganization'];
  const releaseVersion = getReleaseVersion();
  let propertyPlan = null;

  if (propertyId) {
    const property = await store.dispatch('properties/get', [
      propertyId,
      {
        query: {
          $association: 'defaultProperties',
        },
      },
    ]);
    propertyPlan = property?.propertyPlan;
  }

  populateDataLayer(DataLayerEvents.PageLoadStarted, {
    pageCategory: page?.category || '',
    pageName: page?.name || '',
    propertyID: propertyId || '',
    releaseVersion: releaseVersion || '',
    propertyPlan: propertyPlan?.name || '',
  });

  if (store.getters['auth/isAuthenticated']) {
    populateDataLayer(DataLayerEvents.UserDetected, {
      custKey: store.getters['auth/user']?.id || '',
      organizationID: selectedOrganization?.id || '',
      agencyID: selectedOrganization?.agencyId || '',
      organizationPlan: selectedOrganization?.organizationPlan?.name || '',
    });
  }

  populateDataLayer(DataLayerEvents.PageLoadCompleted);
};

export const setPageCategory = pageName => {
  if (pageName.includes('document')) {
    return 'Document';
  }

  if (pageName.includes('design')) {
    return 'Design';
  }

  if (pageName.includes('act')) {
    return 'Act';
  }

  if (pageName.includes('deploy')) {
    return 'Deploy';
  }
};
