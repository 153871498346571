import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class Builds extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Builds';

  static setupInstance(data, { models }) {
    const { PropertyEcosystems, TagManagers } = models.api;

    Object.assign(data, {
      ...(data.propertyEcosystems && {
        propertyEcosystems: data.propertyEcosystems.map(
          propertyEcosystem => new PropertyEcosystems(propertyEcosystem),
        ),
      }),
      ...(data.tagManager && {
        tagManager: new TagManagers(data.tagManager),
      }),
    });

    return data;
  }
}

const servicePath = 'builds';

const servicePlugin = makeServicePlugin({
  Model: Builds,
  service: feathersClient.service(servicePath),
  servicePath,
});

export const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    name: {
      type: 'string',
      title: 'Build Name',
    },
    createdByUserId: {
      type: 'string',
      title: 'Created by',
      'x-display': 'hidden',
    },
    lastUpdatedByUserId: {
      type: 'string',
      title: 'Last Updated by',
      'x-display': 'hidden',
    },
    propertyId: {
      type: 'string',
      'x-display': 'hidden',
    },
    tagManagerId: {
      type: 'string',
      title: 'Tag Manager',
    },
    ecosystem: {
      type: 'string',
      title: 'Created by',
      'x-display': 'hidden',
    },
  },
};

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
