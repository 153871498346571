import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class AdobeLaunchProperties extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'AdobeLaunchProperties';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

const servicePath = 'adobe-launch-properties';
const service = feathersClient.service(servicePath);

const servicePlugin = makeServicePlugin({
  Model: AdobeLaunchProperties,
  service,
  servicePath,
});

service.on('progress', () => {
  // TODO: Send progress to Vuetify snackbar component
});

// Setup the client-side Feathers hooks.
service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
