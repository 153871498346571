import feathersClient, {
  BaseModel,
  makeServicePlugin,
} from '@/config/feathers';

class Invitations extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Invitations';
}

const servicePath = 'invitations';

const servicePlugin = makeServicePlugin({
  Model: Invitations,
  service: feathersClient.service(servicePath),
  servicePath,
  namespace: 'invitations',
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
