import logger from '@/lib/logger';
import { FeatureToggleComponent as featureToggleApi } from 'vue-feature-toggle';
import feathersRestClient from '@/config/feathersRest';

export const initializeFeatureFlags = async () => {
  await feathersRestClient
    .request({
      url: `/feature-flags`,
      method: 'GET',
      responseType: 'application/json',
      params: {
        $limit: -1,
      },
    })
    .then(({ data }) => {
      data.forEach(flag => {
        logger.debug(
          `Feature ${flag.name} (code: ${flag.code}) is ${
            flag.enabled ? 'enabled' : 'disabled'
          }`,
        );
        featureToggleApi.visibility(flag.code, flag.enabled);
      });
    })
    .catch(featureFlagError => {
      logger.error('Error loading feature flags', featureFlagError);
    });
};
