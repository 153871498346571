/**
 * @file Provides utility methods to support code reusability
 * @author Joshua Jack <joshua.jack@searchdiscovery.com>
 */

/**
 * Returns Cookie by name
 * @param {string} name - cookie name
 * @returns {Object} an object containing cookie data
 */
const getCookie = name => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length !== 2) {
    return null;
  }

  return parts.pop().split(';').shift();
};

/**
 * Sets Cookie by name
 * @param {string} name - cookie name
 * @param {string} value - cookie value
 * @param {string} days - cookie expiration in days
 * @returns {Void}
 */
const setCookie = (name, value, days = 1) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = `${name}=${value};path=/;expires=${d.toGMTString()}`;
};

/**
 * Delete Cookie by name
 * @param {string} name - cookie name
 * @returns {Void}
 */
const deleteCookie = name => {
  document.cookie = `${name}=;path=/;expires=0`;
};

/**
 * Search collection for value
 * @param {Array} collection - array of objects
 * @param {string} query - search string
 * @returns {Array}
 */
const searchCollection = (collection = [], query = '') =>
  collection.filter(requirement =>
    Object.values(requirement).find(value => {
      const string = (value || '').toString().toLowerCase();
      return string.includes(query.toLowerCase());
    }),
  );

const sortCollectionByKey = (collection, key = 'name') =>
  collection.sort((a, b) => {
    const nameA = a?.[key].toUpperCase();
    const nameB = b?.[key].toUpperCase();
    const comparison = nameA > nameB ? 1 : 0;
    return nameA < nameB ? -1 : comparison;
  });

const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const downloadFile = (filename, data, isInternetExplorer) => {
  // Do Check for IE
  const url = !isInternetExplorer
    ? window.URL.createObjectURL(new Blob([data]))
    : window.navigator.msSaveOrOpenBlob(new Blob([data]), filename);

  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.target = '_blank';
  link.click();
  link.remove();
};

const isObject = value =>
  typeof value === 'object' && value !== null && Array.isArray(value) === false;

export {
  deleteCookie,
  downloadFile,
  getCookie,
  isObject,
  searchCollection,
  setCookie,
  sortCollectionByKey,
  validateEmail,
};

export default getCookie;
