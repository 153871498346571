import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

export const languages = [
  {
    id: 'en-US',
    name: 'English',
  },
  {
    id: 'es-ES',
    name: 'Spanish',
  },
];

// FIXME: Hack to get the languages automatically added to the locale files
/*
  $t('English')
  $t('Spanish')
*/

function loadLocaleMessages() {
  const locales = require.context(
    '../locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );

  const messages = {};

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}

export default new VueI18n({
  locale:
    window.localStorage.getItem('sdi-apollo-language') ||
    process.env.VUE_APP_I18N_LOCALE ||
    'en-US',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
  messages: loadLocaleMessages(),
  silentTranslationWarn: true,
});
