import { PropertyPlanCodes } from '@/lib/property-plans.enum';
import { EcosystemCodes } from './ecosystems.enum';

const steps = {
  [EcosystemCodes.AdobeAnalytics]: {
    [PropertyPlanCodes.DesignDocument]: 3,
    [PropertyPlanCodes.Premium]: 3,
  },
  [EcosystemCodes.GoogleTagManager]: {
    [PropertyPlanCodes.Basic]: 2,
    [PropertyPlanCodes.EnhancedMigration]: 2,
    [PropertyPlanCodes.DesignDocument]: 3,
    [PropertyPlanCodes.Premium]: 3,
  },
};

export const resolvePropertySteps = (
  propertyPlanCode: string,
  propertyEcosystemCode: string,
  isOrganizationSandbox: boolean,
) => {
  if (isOrganizationSandbox && propertyPlanCode !== PropertyPlanCodes.Basic) {
    // Minus 1 step for properties within sandbox organization as we skip the purchase flow.
    return steps[propertyEcosystemCode]?.[propertyPlanCode] - 1 || 1;
  }

  return steps[propertyEcosystemCode]?.[propertyPlanCode] || 1;
};
