import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class CustomRecordsSync extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'CustomRecordsSync';
}

const servicePath = 'custom-records-sync';

const servicePlugin = makeServicePlugin({
  Model: CustomRecordsSync,
  service: feathersClient.service(servicePath),
  servicePath,
  /*
    idField represents unique field in the response ('id by default').
    Since this is custom service and we don't have 'id' -> 'model' is being used.
  */
  idField: 'model',
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
