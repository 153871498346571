import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class User extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Users';

  // Define default properties here
  static instanceDefaults() {
    return {
      get isAdmin() {
        return this.roles?.includes('admin');
      },
    };
  }
}

export const schema = {
  type: 'object',
  users: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    firstName: {
      type: 'string',
      title: 'First Name',
    },
    lastName: {
      type: 'string',
      title: 'Last Name',
    },
    email: {
      type: 'string',
      title: 'Email',
    },
    extCustomerId: {
      type: 'string',
      title: 'Stripe Customer ID',
    },
    roles: {
      type: 'array',
      title: 'Roles',
      items: {
        type: 'string',
        oneOf: [
          {
            const: 'user',
            title: 'System User',
          },
          {
            const: 'admin',
            title: 'System Admin',
          },
        ],
      },
    },
    createdAt: {
      type: 'string',
      title: 'Created at',
      'x-display': 'hidden',
    },
    updatedAt: {
      type: 'string',
      title: 'Updated at',
      'x-display': 'hidden',
    },
  },
};

const servicePath = 'users';
const servicePlugin = makeServicePlugin({
  Model: User,
  service: feathersClient.service(servicePath),
  servicePath,
  namespace: 'users',
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
