import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class EventAttributes extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'EventAttributes';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }

  static setupInstance(data, { models }) {
    if (data.trackingItems) {
      data.trackingItems = data.trackingItems.map(
        trackingItem => new models.api.TrackingItems(trackingItem),
      );
    }
    return data;
  }
}

const servicePath = 'event-attributes';

const servicePlugin = makeServicePlugin({
  Model: EventAttributes,
  service: feathersClient.service(servicePath),
  servicePath,
  whitelist: ['$ne'],
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
