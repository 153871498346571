<template lang="pug">
v-navigation-drawer.navigation(
  :width='disabled ? 64 : 256'
  app
  clipped
  expand-on-hover
  left
  mini-variant-width='64'
  permanent
)
  v-list(v-if='!disabled')
    v-list-item-group(
      :value='selectedTab'
      color='primary'
    )
      v-list-item.list-item-style(
        :class='[{ "disabled-navigation-row": !item.hasAccess, "v-item--active v-list-item--active": selectedTab == item.tab }]'
        :disabled='item.key === "deploy" && readonlyMode'
        :key='item.title'
        :ripple='item.hasAccess'
        :to='item.hasAccess ? item.to : ""'
        @click='item.click ? item.click() : null'
        @mouseleave='hoveredTabChange("", item.hasAccess)'
        @mouseover='hoveredTabChange(item, item.hasAccess)'
        link
        v-for='item in sideBarItems'
      )
        v-list-item-icon 
          v-icon
            | {{ item.icon }}
        v-list-item-content(align-items='center')
          v-list-item-title.ml-3
            | {{ item.title }}
        .custom-tooltip(v-if='hoveredTab === item.key')
          span {{ $t(`${item.title} is not available in the basic plan.`) }}
</template>

<script>
import { computed, ref } from '@vue/composition-api';

export default {
  name: 'PropertyNavigation',
  props: {
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
    sideBarItems: {
      type: Array,
      default: () => [],
    },
    selectedTab: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    propertySetupStatus: {
      type: String,
      default: () => '',
    },
  },
  setup(props) {
    const readonlyMode = computed(() =>
      [
        'map_existing_implementation_in_progress',
        'map_existing_implementation_in_progress_legacy',
      ].includes(props.propertySetupStatus),
    );

    const hoveredTab = ref('');

    const hoveredTabChange = (state, hasAccess) => {
      if (hasAccess) {
        return;
      }
      hoveredTab.value = state.key;
    };

    return {
      readonlyMode,
      hoveredTabChange,
      hoveredTab,
    };
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/scss/colors.scss';

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.8;
  z-index: -1;
}
.v-navigation-drawer {
  .v-list {
    padding: 0;
    .v-list-item {
      height: 64px;
      .v-list-item__icon {
        margin: auto !important;
        .v-icon {
          font-size: 24px;
        }
      }
    }
    .v-list-item--link:before {
      border-radius: 0;
    }
  }
  .v-sheet.v-list:not(.v-sheet--outlined) {
    border-top: none;
    box-shadow: none;
  }
  &.v-navigation-drawer--is-mouseover {
    .v-list-item__icon {
      padding-left: 12px;
    }
  }
}

.v-item--active {
  .v-icon,
  .v-list-item__title {
    color: $white !important;
  }
}

.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant
  .v-list-item {
  padding: 0;
}

.v-list--disabled {
  opacity: 0.3;

  .v-list-item--link {
    color: transparent;

    .v-list-item__icon .v-icon {
      color: $dark !important;
    }
  }
}

.v-navigation-drawer--clipped:not(.v-navigation-drawer--temporary):not(.v-navigation-drawer--is-mobile) {
  z-index: 6;
}

::v-deep .v-navigation-drawer__content {
  overflow: visible !important;
}

.navigation {
  overflow: visible !important;
}

.list-item-style {
  position: relative;

  .custom-tooltip {
    position: absolute !important;
    left: 100%;
    top: 0;
    bottom: 0;
    color: $lightgrey;
    background: $apollodark;
    width: 220px;
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    border-radius: 5px;

    &:before {
      content: '';
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent $apollodark transparent transparent;
      position: absolute;
      left: -10px;
      top: 22px;
    }
  }
}

.disabled-navigation-row {
  color: $white !important;

  &:before {
    background-color: $white !important;
  }

  .v-list-item__icon,
  .v-list-item__content {
    opacity: 0.5;
  }

  .theme--light.v-icon {
    color: $apollodark !important;
  }

  .v-list-item__title {
    color: $apollodark !important;
  }
}
</style>
