import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';
import { AgencyRoles } from '@/lib/roles';

class AgencyUsers extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'AgencyUsers';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

export const schema = {
  type: 'object',
  agencyUsers: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    userId: {
      type: 'string',
      title: 'Email',
    },
    agencyId: {
      type: 'string',
      title: 'Agency',
    },
    createdAt: {
      type: 'string',
      title: 'Created at',
      'x-display': 'hidden',
    },
    updatedAt: {
      type: 'string',
      title: 'Updated at',
      'x-display': 'hidden',
    },
    deletedAt: {
      type: 'string',
      title: 'Deleted at',
      'x-display': 'hidden',
    },
  },
};

const servicePath = 'agency-users';

const servicePlugin = makeServicePlugin({
  Model: AgencyUsers,
  service: feathersClient.service(servicePath),
  servicePath,
  getters: {
    isAgencyAdmin: (state, getters, rootState) => {
      const { user } = rootState.auth;

      if (!user || !user.id) {
        return false;
      }

      return !!getters.find({
        query: {
          userId: user.id,
          roles: [AgencyRoles.Admin],
        },
      })?.data?.length;
    },
  },
  namespace: 'agency-users',
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
