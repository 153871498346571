import store from '@/stores';
import { PermissionTypes } from './permissions';

/**
 * Returns true/false for user access to certain action based on propertyId or organizationId and permission code
 *
 * @param {string} permissionCode unique code of the permission f.e. manage_business_requirements, check PermissionCodes enum
 * @param {string} subjectId property or organization id, default gets it from store
 * @param {PermissionTypes} permissionType 'organization' or 'property', default is 'property'
 * @return {boolean} true/false
 */

export const hasAccess = (
  permissionCode,
  permissionType = PermissionTypes.Property,
  subjectId = null,
) => {
  const permissions = store.getters['auth/userPermissions'];

  // if user doesn't have any access to such permission type
  if (permissions[permissionType]) {
    if (subjectId === null) {
      if (permissionType === PermissionTypes.Property) {
        subjectId = store.getters['properties/selectedPropertyId'];
      }
      if (permissionType === PermissionTypes.Organization) {
        subjectId = store.getters['auth/selectedOrganizationId'];
      }
    }
  } else {
    return false;
  }

  // if user doesn't have any access to such permission
  if (!permissions[permissionType][permissionCode]) {
    return false;
  }

  return permissions[permissionType][permissionCode][subjectId] || false;
};
