import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class SignUp extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'SignUps';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

export const schema = {
  type: 'object',
  signups: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    firstName: {
      type: 'string',
      title: 'First Name',
    },
    lastName: {
      type: 'string',
      title: 'Last Name',
    },
    email: {
      type: 'string',
      title: 'Email',
    },
    organizationId: {
      type: 'string',
      title: 'Organization Id',
    },
    organizationName: {
      type: 'string',
      title: 'Organization Name',
    },
    organizationPlan: {
      type: 'string',
      title: 'Organization Plan',
    },
    utmCampaign: {
      type: 'string',
      'x-display': 'hidden',
    },
    utmSource: {
      type: 'string',
      'x-display': 'hidden',
    },
    utmMedium: {
      type: 'string',
      'x-display': 'hidden',
    },
    utmContent: {
      type: 'string',
      'x-display': 'hidden',
    },
    utmTerm: {
      type: 'string',
      'x-display': 'hidden',
    },
    activity: {
      type: 'string',
      'x-display': 'hidden',
    },
    createdAt: {
      type: 'string',
      title: 'Created at',
      'x-display': 'hidden',
    },
    updatedAt: {
      type: 'string',
      title: 'Updated at',
      'x-display': 'hidden',
    },
  },
};

const servicePath = 'signups';
const servicePlugin = makeServicePlugin({
  Model: SignUp,
  service: feathersClient.service(servicePath),
  servicePath,
  namespace: 'signups',
  idField: 'userId',
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
