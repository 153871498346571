import { styled } from '@egoist/vue-emotion';
import { VContainer, VMain, VApp } from 'vuetify/lib';
import { inject } from '@vue/composition-api';

//TODO: Remove AppContainer style declaration once we update the Document > Data Layer screen

const AppContainer = styled(VContainer)`
  code,
  kbd {
    font-weight: initial !important;

    &:before {
      content: none !important;
    }
  }

  .hljs {
    width: 100%;
  }

  .v-data-table td,
  .v-data-table th {
    padding: 10px 16px;
  }

  .row {
    margin: initial;
    margin-left: -12px;
    margin-right: -12px;
  }

  .v-application code {
    background-color: light-gray !important;
  }
`;

const AppContent = styled(VMain)`
  background-color: ${() =>
    inject('themeConfig')?.custom?.container} !important;
`;

export { AppContent, AppContainer };
