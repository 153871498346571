export default {
  namespaced: true,
  state: {
    client: null,
  },
  getters: {
    client: state => state.client,
  },
  mutations: {
    setClient(state, client) {
      state.client = client;
    },
  },
  actions: {
    async setClient({ commit }, client) {
      commit('setClient', client);
    },

    async clearClient({ commit }) {
      commit('setClient', null);
    },
  },
};
