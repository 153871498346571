import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class EcosystemConfigTypes extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'EcosystemConfigTypes';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

// TODO: Figure out best way to go about translating these
export const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    code: {
      type: 'string',
    },
    dataDestinationId: {
      type: 'string',
      title: 'Data Destination',
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    schema: {
      type: 'string',
      title: 'Schema',
      'x-display': 'textarea',
    },
  },
};

const servicePath = 'ecosystem-config-types';

const servicePlugin = makeServicePlugin({
  Model: EcosystemConfigTypes,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
