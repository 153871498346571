import Vue from 'vue';
import Vuex from 'vuex';
import { FeathersVuex } from '@/config/feathers';
import auth from './auth';
import preferences from './modules/preferences';

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireServices = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/,
);

const servicePlugins = requireServices
  .keys()
  .map(servicePath => requireServices(servicePath).default);

const requireModules = require.context(
  // The path where the service modules live
  './modules',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /.js$/,
);
const storeModules = {};
requireModules.keys().forEach(key => {
  const mod = requireModules(key);
  storeModules[key.slice(2, -3)] = mod.default ? mod.default : mod;
});

const store = new Vuex.Store({
  plugins: [...servicePlugins, auth],
  modules: { ...storeModules, preferences },
});

export default store;
