import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class Properties extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Properties';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }

  // WARNING: Only a single instance of a data record can be in the store at any one time.
  // When you convert an association to a model, it replaces the existing store item
  // with the shallow one from here and does not automatically "get" the data record again.
  // This means the store item will not have any associations that are on the foreign model by default until
  // you explicitly re-fetch the associated record. Also, explicitly re-fetching could introduce a security
  // vulnerability since nested association items from one company could be loaded into the store for another company.
  //
  // Given this, converting associations to models can be unpredictable and should be avoided.
  // The exception is for a junction table that comes through with a many:many association.
  // This can be converted such that the association can be explicitly updated or deleted later more easily.
  // However, even junction tables sometimes have necessary associations as well, so be careful...
}

const servicePath = 'properties';
const servicePlugin = makeServicePlugin({
  Model: Properties,
  service: feathersClient.service(servicePath),
  servicePath,
  namespace: 'properties',
  state: {
    selectedPropertyId: null,
  },

  getters: {
    selectedPropertyId: state => state.selectedPropertyId,
  },

  mutations: {
    selectPropertyId(state, { selectedPropertyId }) {
      state.selectedPropertyId = selectedPropertyId;
    },
    clearSelection(state) {
      state.selectedPropertyId = null;
    },
  },
  actions: {
    selectPropertyId({ commit }, selectedPropertyId) {
      commit('selectPropertyId', { selectedPropertyId });
    },
    clearSelection({ commit }) {
      commit('clearSelection');
    },
  },

  handleEvents: {
    created: () => true,
    updated: () => true,
    patched: (item, { model, models }) => {
      const existing = Properties.findInStore({ query: { id: item.id } });

      // check if the `propertyAvailabilityStatus` status have changed from Map In Progress to Available
      // if so, we'll put some field for the property model
      // this will allow us to react to the change and refresh the property list
      if (
        existing.data?.[0]?.propertyAvailabilityStatus === 'map_in_progress' &&
        item.propertyAvailabilityStatus === 'available'
      ) {
        item.propertyAvailabilityStatusChanged = true;
      }

      return true;
    },
    removed: item => true,
  },
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
