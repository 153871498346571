import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class Events extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Events';

  // Define default properties here
  static instanceDefaults() {
    return {
      name: '',
      description: '',
      eventId: null,
    };
  }

  // Used for instance specific transforms
  // static setupInstance() {}
}
export const schema = {
  type: 'object',
  propertyEvent: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    config: {
      type: 'object',
    },
  },
  properties: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    name: {
      type: 'string',
      description: 'Enter the name',
      title: 'Name',
    },
    description: {
      type: 'string',
      title: 'Description',
      'x-display': 'textarea',
    },
  },
};
const servicePath = 'events';

const servicePlugin = makeServicePlugin({
  Model: Events,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
