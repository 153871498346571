import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class BusinessRequirementRanks extends BaseModel {
  static modelName = 'BusinessRequirementRanks';

  static instanceDefaults() {
    return {
      name: '',
      code: '',
      weight: 100,
    };
  }
}

export const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    name: {
      type: 'string',
      title: 'Name',
    },
    code: {
      type: 'string',
      title: 'Code',
      'x-display': 'hidden',
    },
    weight: {
      type: 'integer',
      title: 'Weight',
      'x-display': 'hidden',
    },
  },
};

const servicePath = 'business-requirement-ranks';

const servicePlugin = makeServicePlugin({
  Model: BusinessRequirementRanks,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
