import i18n from '@/config/i18n';

/**
 * If you are adding validation rules for Vuetify components, please keep in mind that they should return a Boolean (true) if validation passes
 * Otherwise, it should return the valdiation message
 */

const jsReservedWords = [
  'await',
  'break',
  'case',
  'catch',
  'class',
  'const',
  'continue',
  'debugger',
  'default',
  'delete',
  'do',
  'else',
  'enum',
  'export',
  'extends',
  'false',
  'finally',
  'for',
  'function',
  'if',
  'implements',
  'import',
  'in',
  'instanceof',
  'interface',
  'let',
  'new',
  'null',
  'package',
  'private',
  'protected',
  'public',
  'return',
  'super',
  'switch',
  'static',
  'this',
  'throw',
  'try',
  'True',
  'typeof',
  'var',
  'void',
  'while',
  'with',
  'yield',
];

const dataLayerNameValidationRule = value => {
  if (jsReservedWords.indexOf(value) !== -1) {
    return i18n.t('Invalid: not a valid JavaScript identifier');
  }

  // should start with either a letter, underscore (_) or dollar sign ($)
  // should contain only letters, numbers, underscore (_) and dollar sign ($)
  const dataLayerNameRegex = new RegExp(/^[a-zA-Z_$][0-9a-zA-Z_$]*$/, 'i');

  return (
    dataLayerNameRegex.test(value) ||
    i18n.t('Invalid: not a valid JavaScript identifier')
  );
};

export { dataLayerNameValidationRule, jsReservedWords };

export default dataLayerNameValidationRule;

const onlyLettersNumbersSpacesDashesUnderscores = extend => {
  extend('only_letters_numbers_spaces_dashes_underscores', {
    message: i18n.t(
      'Only letters, numbers, spaces, dashes, and underscores are allowed',
    ),
    validate: value => /^[\sa-zA-Z0-9_-]*$/.test(value),
  });
};

const onlyLettersNumbersDashesUnderscoresDots = extend => {
  extend('only_letters_numbers_dashes_underscores_dots', {
    message: i18n.t(
      'Only letters, numbers, dashes, underscores and dots are allowed',
    ),
    validate: value => /^[a-zA-Z0-9_.-]*$/.test(value),
  });
};

const onlyNumbers = extend => {
  extend('only_numbers', {
    message: i18n.t('Only numbers are allowed'),
    validate: value => /^[0-9]+$/.test(value),
  });
};

const startsWithLetterAllowsAlphanumericCharactersOnly = extend => {
  extend('starts_with_letter_allows_alphanumeric_characters_only', {
    message: i18n.t(
      'It should start with letter. Only letters, numbers and underscores are allowed.',
    ),
    validate: value => /^[a-zA-Z][0-9a-zA-Z_]*$/.test(value),
  });
};

const ecommerceItemCheck = extend => {
  extend('ecommerce_item_check', {
    message: i18n.t('The value for the field should be ecommerce.items'),
    validate: value => value === 'ecommerce.items',
  });
};

export const globalRules = [
  onlyLettersNumbersSpacesDashesUnderscores,
  onlyLettersNumbersDashesUnderscoresDots,
  onlyNumbers,
  startsWithLetterAllowsAlphanumericCharactersOnly,
  ecommerceItemCheck,
];

export const extendRules = (veeExtend, rules) => {
  if (veeExtend && rules && rules.length) {
    rules.forEach(rule => {
      rule(veeExtend);
    });
  }
};
