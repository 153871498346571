export default {
  light: {
    primary: '#00a5b4',
    secondary: '#d8f1f4',
    apollodark: '#1d3337',
    error: '#ed1b23',
    success: '#00ae4d',
    warning: '#ffd42f',
    dark: '#221f20',
    grey: '#ebebed',
    grey2: '#a19e9d',
    lightgrey: '#f3f3f5',
    darkgrey: '#7a7574',
    darkenlightgrey: '#C6C4C4',
    attribute: '#f58223',
    attributeSecondary: '#fdebdd',
    event: '#9a248f',
    eventSecondary: '#efdded',
    variable: '#0460af',
    variableSecondary: '#d7e5f1',
    pink: '#ec128c',
    yellow: '#ffd42f',
    dataSource: '#85c440',
    dataSourceSecondary: '#ecf6e1',
    white: '#ffffff',
    surface: '#f2f4f7',
    custom: {
      card: '#fff',
      code: '#eee',
      container: '#f2f4f7',
      logo: 'images/apollo-logo-white.svg',
      row: {
        odd: '#eaeaea',
      },
    },
    complete: '#d9f3e4',
    incomplete: '#fff9e0',
    notStarted: '#f3f3f5',
    secondaryRed: '#ffe6e6',
    primaryRed: '#e60000',
    headerlabel: '#76afd4',
    placeholder: '#a19e9d',
    greychip: '#a09f9d',
    intercom: '#f07e22',
    agency: '#b6e0e5',
    organization: '#b6e0e5',
    property: '#b6e0e5',
    customDataDesigner: {
      event: '#9a248f26',
      attribute: '#f5822326',
    },
  },
};
