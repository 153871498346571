export const getPageInfo = routeName => {
  let page = {};

  switch (routeName) {
    case '404':
      page = {
        category: 'Error',
        name: 'Error 404',
      };
      break;

    case 'login':
      page = {
        category: 'Authentication',
        name: 'Login',
      };
      break;

    case 'oauth':
      page = {
        category: 'Authentication',
        name: 'Authentication Completed',
      };
      break;

    case 'home':
      page = {
        category: 'Home',
        name: 'Home',
      };
      break;

    case 'Signup':
      page = {
        category: 'Authentication',
        name: 'Sign Up',
      };
      break;
    /**
     * Properties
     */

    case 'properties':
      page = {
        category: 'Properties',
        name: 'Properties List',
      };
      break;

    case 'properties.new.step1':
      page = {
        category: 'Properties',
        name: 'Properties List | New Property',
      };
      break;

    case 'properties.created.step1':
      page = {
        category: 'Properties',
        name: 'Properties List | Property In Progress',
      };
      break;

    case 'properties.new.step2':
      page = {
        category: 'Properties',
        name: 'New Property | Step 2',
      };
      break;

    case 'properties.new.step3':
      page = {
        category: 'Properties',
        name: 'New Property | Step 3',
      };
      break;

    case 'properties.new.step4':
      page = {
        category: 'Properties',
        name: 'New Property | Step 4',
      };
      break;

    case 'properties.new.step5':
      page = {
        category: 'Properties',
        name: 'New Property | Step 5',
      };
      break;

    /**
     * Design
     */

    case 'property.design.businessRequirements':
      page = {
        category: 'Design',
        name: 'Business Requirements',
      };
      break;
    case 'property.design.businessRequirements.templates.new':
      page = {
        category: 'Design',
        name: 'Business Requirements | New Template',
      };
      break;
    case 'property.design.businessRequirements.template.edit':
      page = {
        category: 'Design',
        name: 'Business Requirements | Edit Template',
      };
      break;
    case 'property.design.assignEcosystemKeys':
      page = {
        category: 'Design',
        name: 'Assign Custom Variables',
      };
      break;
    case 'property.design.eventAttributes':
      page = {
        category: 'Design',
        name: 'Event Attributes',
      };
      break;
    case 'property.design.data-sources':
      page = {
        category: 'Design',
        name: 'Data Sources',
      };
      break;
    case 'property.design.mapExistingImplementation':
      page = {
        category: 'Design',
        name: 'Map Existing Implementation',
      };
      break;

    case 'property.design.mapExistingImplementation.event-new':
      page = {
        category: 'Design',
        name: 'Map Existing Implementation : Create Event',
      };
      break;

    case 'property.design.mapExistingImplementation.attribute-select':
      page = {
        category: 'Design',
        name: 'Map Existing Implementation : Select Attribute',
      };
      break;

    case 'property.design.mapExistingImplementation.attribute-new':
      page = {
        category: 'Design',
        name: 'Map Existing Implementation : Create Attribute',
      };
      break;

    case 'property.design.mapExistingImplementation.variable-select':
      page = {
        category: 'Design',
        name: 'Map Existing Implementation : Select Variable',
      };
      break;

    case 'property.design.mapExistingImplementation.variable-new':
      page = {
        category: 'Design',
        name: 'Map Existing Implementation : Create Variable',
      };
      break;

    case 'property.design.mapExistingImplementation.data-source-select':
      page = {
        category: 'Design',
        name: 'Map Existing Implementation : Select Data Source',
      };
      break;

    case 'property.design.mapExistingImplementation.data-source-new':
      page = {
        category: 'Design',
        name: 'Map Existing Implementation : Create Data Source',
      };
      break;

    case 'property.design.mapExistingImplementation.tracking-item-edit':
      page = {
        category: 'Design',
        name: 'Map Existing Implementation : Edit Tracking Settings',
      };
      break;

    case 'property.design.qaTests':
      page = {
        category: 'Design',
        name: 'QA Tests',
      };
      break;

    case 'property.design.businessRequirements.br-details':
      page = {
        category: 'Design',
        name: 'Business Requirement Details Drawer',
      };
      break;
    case 'property.design.businessRequirements.event-details':
      page = {
        category: 'Design',
        name: 'Event Details Drawer',
      };
      break;
    case 'property.design.businessRequirements.attribute-details':
      page = {
        category: 'Design',
        name: 'Attribute Details Drawer',
      };
      break;
    case 'property.design.businessRequirements.data-source-details':
      page = {
        category: 'Design',
        name: 'Data Source Details Drawer',
      };
      break;

    case 'property.design.businessRequirements.variable-details':
      page = {
        category: 'Design',
        name: 'Variable Details Drawer',
      };
      break;

    case 'property.design.businessRequirements.tracking-item-details':
      page = {
        category: 'Design',
        name: 'Tracking Settings Details Drawer',
      };
      break;

    case 'property.design.businessRequirements.br-new':
      page = {
        category: 'Design',
        name: 'Create Business Requirement Drawer',
      };
      break;

    case 'property.design.businessRequirements.event-new':
      page = {
        category: 'Design',
        name: 'Create Event Drawer',
      };
      break;

    case 'property.design.businessRequirements.attribute-new':
      page = {
        category: 'Design',
        name: 'Create Attribute Drawer',
      };
      break;

    case 'property.design.businessRequirements.variable-new':
      page = {
        category: 'Design',
        name: 'Create Variable Drawer',
      };
      break;

    case 'property.design.businessRequirements.datasource-new':
      page = {
        category: 'Design',
        name: 'Create Data Source Drawer',
      };
      break;

    case 'property.design.businessRequirements.edit.event-attributes':
      page = {
        category: 'Design',
        name: 'Add Event Attribute to Business Requirement Drawer',
      };
      break;

    case 'property.design.businessRequirements.br-edit':
      page = {
        category: 'Design',
        name: 'Edit Business Requirement Drawer',
      };
      break;
    case 'property.design.businessRequirements.event-edit':
      page = {
        category: 'Design',
        name: 'Edit Event Drawer',
      };
      break;

    case 'property.design.businessRequirements.attribute-edit':
      page = {
        category: 'Design',
        name: 'Edit Attribute Drawer',
      };
      break;

    case 'property.design.businessRequirements.datasource-edit':
      page = {
        category: 'Design',
        name: 'Edit Data Source Drawer',
      };
      break;

    case 'property.design.businessRequirements.variable-edit':
      page = {
        category: 'Design',
        name: 'Edit Variable Drawer',
      };
      break;

    case 'property.design.businessRequirements.tracking-item-edit':
      page = {
        category: 'Design',
        name: 'Edit Tracking Settings Drawer',
      };
      break;

    case 'property.design.businessRequirements.select-event':
      page = {
        category: 'Design',
        name: 'Select Event Drawer',
      };
      break;

    case 'property.design.businessRequirements.select-attribute':
      page = {
        category: 'Design',
        name: 'Add Attributes to Event Drawer',
      };
      break;

    case 'property.design.businessRequirements.variable-select':
      page = {
        category: 'Design',
        name: 'Select Existing Variable Drawer',
      };
      break;

    case 'property.design.businessRequirements.selectDataSourceConfig':
      page = {
        category: 'Design',
        name: 'Select Existing Data Source Drawer',
      };
      break;

    case 'property.design.businessRequirements.br-replace':
      page = {
        category: 'Design',
        name: 'Replace Business Requirement Drawer',
      };
      break;

    case 'property.design.businessRequirements.event-replace':
      page = {
        category: 'Design',
        name: 'Replace Event Drawer',
      };
      break;

    case 'property.design.businessRequirements.attribute-replace':
      page = {
        category: 'Design',
        name: 'Replace Attribute Drawer',
      };
      break;

    case 'property.design.businessRequirements.datasource-replace':
      page = {
        category: 'Design',
        name: 'Replace Data Source Drawer',
      };
      break;

    case 'property.design.businessRequirements.variable-replace':
      page = {
        category: 'Design',
        name: 'Replace Variable Drawer',
      };
      break;

    case 'property.design.businessRequirements.tracking-item-replace':
      page = {
        category: 'Design',
        name: 'Replace Tracking Settings Drawer',
      };
      break;

    /**
     * Document
     */

    case 'property.document.dataLayer':
      page = {
        category: 'Document',
        name: 'Data Layer',
      };
      break;

    case 'property.document.dataEnrichment':
      page = {
        category: 'Document',
        name: 'Data Enrichment',
      };
      break;

    case 'property.document.documentation':
      page = {
        category: 'Document',
        name: 'Data Destination',
      };
      break;

    case 'property.document.visualSDR':
      page = {
        category: 'Document',
        name: 'Visual SDR',
      };
      break;

    /**
     * Deploy
     */

    case 'property.deploy.calculatedMetrics':
      page = {
        category: 'Deploy',
        name: 'Calculated Metrics',
      };
      break;

    case 'property.deploy.dashboards':
      page = {
        category: 'Deploy',
        name: 'Dashboards',
      };
      break;

    case 'property.deploy.liftAndShift':
      page = {
        category: 'Document',
        name: 'GTM Lift And Shift',
      };
      break;

    case 'property.deploy.builds':
      page = {
        category: 'Deploy',
        name: 'Builds',
      };
      break;

    case 'property.deploy.builds-basic':
      page = {
        category: 'Deploy',
        name: 'Builds Free Property',
      };
      break;

    case 'property.deploy.build.new':
      page = {
        category: 'Deploy',
        name: 'Builds | New Build',
      };
      break;

    case 'property.deploy.build.edit':
      page = {
        category: 'Deploy',
        name: 'Builds | Edit Build',
      };
      break;

    case 'property.deploy.build.deploy':
      page = {
        category: 'Deploy',
        name: 'Builds | Deploy Build',
      };
      break;

    /**
     * ACT
     */

    case 'property.act.businessUser':
      page = {
        category: 'Act',
        name: 'Business User',
      };
      break;

    // TBD
    case 'oauth':
      page = {
        category: 'Oauth',
        name: 'Oauth Completed',
      };
      break;

    /**
     * Admin
     */

    case 'admin':
      page = {
        category: 'Admin',
        name: 'Admin',
      };
      break;

    case 'admin.users':
      page = {
        category: 'Admin',
        name: 'Admin Users',
      };
      break;

    case 'admin.users.new':
      page = {
        category: 'Admin',
        name: 'Admin Users | New User',
      };
      break;

    case 'admin.user.edit':
      page = {
        category: 'Admin',
        name: 'Admin Users | Edit User',
      };
      break;

    case 'admin.featureFlags':
      page = {
        category: 'Admin',
        name: 'Admin | Feature Flags',
      };
      break;

    /**
     * Agency
     */

    case 'admin.agencies':
      page = {
        category: 'Admin',
        name: 'Agencies',
      };
      break;

    case 'admin.agency.new':
      page = {
        category: 'Admin',
        name: 'Agencies | New Agency',
      };
      break;

    case 'admin.agency.edit':
      page = {
        category: 'Admin',
        name: 'Agencies | Edit Agency',
      };
      break;

    case 'admin.agency.users':
      page = {
        category: 'Admin',
        name: 'Agency Users',
      };
      break;

    case 'admin.agency.users.new':
      page = {
        category: 'Admin',
        name: 'Agency Users | New User',
      };
      break;

    /**
     * Organizations
     */
    case 'admin.organizations':
      page = {
        category: 'Admin',
        name: 'Organizations',
      };
      break;
    case 'admin.organization.users':
      page = {
        category: 'Admin',
        name: 'Organization Users',
      };
      break;

    /**
     * Users
     */
    case 'users.forgotPassword':
      page = {
        category: 'Users',
        name: 'Forgot Password',
      };
      break;

    case 'users.verifyResetPassword':
      page = {
        category: 'Users',
        name: 'Verify Reset Password',
      };
      break;
  }

  return page;
};
