import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class OrganizationPlans extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'OrganizationPlans';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

const servicePath = 'organization-plans';

const servicePlugin = makeServicePlugin({
  Model: OrganizationPlans,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
