<template lang="pug">
v-btn#help-icon.ml-4(
  :ripple='false'
  @click='helpIconClicked'
  color='variable'
  data-qa='header-help-btn'
  elevation='0'
  fab='fab'
  href='https://docs.apolloplatform.com/en/'
  small
  target='_blank'
)
  v-icon.docs far fa-question-circle
</template>

<script>
import { populateDataLayer } from '@/lib/populateDataLayer';
import { DataLayerEvents } from '@/lib/dataLayerEvents.enum';

export default {
  name: 'HelpIcon',
  setup(props) {
    const helpIconClicked = () => {
      populateDataLayer(DataLayerEvents.ExitLinkClicked, {
        linkId: 'Help Button',
      });
    };
    return {
      helpIconClicked,
    };
  },
};
</script>

<style lang="scss" scoped>
#help-icon {
  position: fixed;
  z-index: 9999;
  top: 16px;
  right: 56px;
}
</style>
