import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';
import { OrganizationRoles } from '@/lib/roles';

class OrganizationUsers extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'OrganizationUsers';

  // Define default properties here
  static instanceDefaults() {
    return {
      get isAdmin() {
        return this.roles?.includes('admin');
      },
      organization: {},
    };
  }
}

const servicePath = 'organization-users';

const servicePlugin = makeServicePlugin({
  Model: OrganizationUsers,
  service: feathersClient.service(servicePath),
  servicePath,
  getters: {
    organizations: (state, getters, rootState) => {
      const { user } = rootState.auth;

      if (!user || !user.id) {
        return [];
      }

      const organizations = getters.find({
        query: { userId: user.id },
      })?.data;

      return organizations.map(org => ({
        ...org.organization,
        role: org.roles[0],
      }));
    },

    isOrganizationAdmin: (state, getters, rootState) => {
      const { user } = rootState.auth;

      if (!user || !user.id) {
        return false;
      }

      return !!getters.find({
        query: {
          userId: user.id,
          roles: [OrganizationRoles.Admin],
        },
      })?.data?.length;
    },

    haveOrganizations: (state, getters) => !!getters.organizations?.length,
  },
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
