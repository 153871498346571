export default {
  namespaced: true,
  state: {
    connectionDetails: {},
  },
  getters: {
    connectionDetails: state => state.connectionDetails,
  },
  mutations: {
    connectionDetails(state, { details }) {
      state.connectionDetails = details;
    },
  },
  actions: {
    async setConnectionDetails({ commit }, details) {
      commit('connectionDetails', { details });
    },
    async clearConnectionDetails({ commit }) {
      commit('connectionDetails', {});
    },
  },
};
