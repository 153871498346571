// property-tag-manager-ext-imports
import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class PropertyTagManagerExtImports extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'PropertyTagManagerExtImports';
}

const servicePath = 'property-tag-manager-ext-imports';

const servicePlugin = makeServicePlugin({
  Model: PropertyTagManagerExtImports,
  service: feathersClient.service(servicePath),
  servicePath,
  idField: 'identifier',
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [
      // HACK: This supports accessing the initial state
      // After a find has been completed to determine
      // Objects that require saving
      context => {
        context.service.FeathersVuexModel._initialState = JSON.parse(
          JSON.stringify(context.result),
        );

        return context;
      },
    ],
    get: [],
    create: [
      // HACK: This supports accessing the initial state
      // After a patch has been completed to determine
      // Subsequent Objects that require saving
      context => {
        const patchedModelIndex =
          context.service.FeathersVuexModel._initialState.findIndex(
            propertyTagManagerExtImport =>
              propertyTagManagerExtImport.id === context.result.id,
          );

        if (patchedModelIndex) {
          context.service.FeathersVuexModel._initialState[patchedModelIndex] =
            context.result;
          return context;
        }

        context.service.FeathersVuexModel._initialState = [
          ...(context.service?.FeathersVuexModel?._initialState?.length
            ? context.service.FeathersVuexModel._initialState
            : []),
          context.result,
        ];

        return context;
      },
    ],
    update: [],
    patch: [
      // HACK: This supports accessing the initial state
      // After a patch has been completed to determine
      // Subsequent Objects that require saving
      context => {
        const patchedModelIndex =
          context.service.FeathersVuexModel._initialState.findIndex(
            propertyTagManagerExtImport =>
              propertyTagManagerExtImport.id === context.result.id,
          );

        context.service.FeathersVuexModel._initialState[patchedModelIndex] =
          context.result;
        return context;
      },
    ],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
