import * as winston from 'winston';
import 'setimmediate';
import BrowserConsole from 'winston-transport-browserconsole';

const level = process.env.VUE_APP_LOG_LEVEL || 'info';
winston.configure({
  transports: [
    new BrowserConsole({
      format: winston.format.simple(),
      level,
    }),
  ],
});

export default winston;
