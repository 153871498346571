import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class GoogleTagManagerAccounts extends BaseModel {
  static modelName = 'GoogleTagManagerAccounts';
}

const servicePath = 'google-gtm-accounts';
const service = feathersClient.service(servicePath);

const servicePlugin = makeServicePlugin({
  Model: GoogleTagManagerAccounts,
  service,
  servicePath,
  idField: 'name',
});

service.on('progress', () => {
  // TODO: Send progress to Vuetify snackbar component
});

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
