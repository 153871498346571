import feathersClient, {
  BaseModel,
  makeServicePlugin,
} from '@/config/feathers';

class Queue extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Queue';
}

const servicePath = 'queue';
const service = feathersClient.service(servicePath);

const servicePlugin = makeServicePlugin({
  Model: Queue,
  service,
  servicePath,
});

service.on('progress', async data => {
  const existing = Queue.getFromStore(data?.id);

  if (!existing) {
    return;
  }

  const updated = new Queue({
    ...existing,
  });

  const updatedClone = updated.clone();

  if (typeof updatedClone.progress === 'number') {
    updatedClone.progress = data.progress;
  }

  updatedClone.progress.description += `\n${data.progress.description}`;

  updatedClone.progress.progress = data.progress.progress;

  await updatedClone.commit();
});

// Setup the client-side Feathers hooks.
service.hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
