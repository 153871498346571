export enum PropertyPlanCodes {
  Basic = 'basic',
  DesignDocument = 'design-document',
  EnhancedMigration = 'enhanced-migration',
  Premium = 'premium',
}

export enum PropertyPlanNames {
  Basic = 'Basic',
  DesignDocument = 'Design & Document',
  EnhancedMigration = 'Enhanced Migration',
  Premium = 'Premium',
}

export enum PropertyPlanPermissionsGroupCodes {
  Basic = 'basic-property',
  DesignDocument = 'design-document-property',
  EnhancedMigration = 'enhanced-migration-property',
  Premium = 'premium-property',
}

export enum PropertyPlanPermissionsGroupNames {
  Basic = 'Basic Property',
  DesignDocument = 'Design & Document Property',
  EnhancedMigration = 'Enhanced Migration Property',
  Premium = 'Premium Property',
}
