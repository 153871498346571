export default {
  namespaced: true,
  state: {
    alerts: [],
  },
  getters: {
    alerts: state => state.alerts,
  },
  mutations: {
    alerts(state, { alerts, clear }) {
      state.alerts = clear ? alerts : [...state.alerts, ...alerts];
    },
  },
  actions: {
    async addAlerts({ commit }, alerts) {
      commit('alerts', { alerts, clear: false });
    },
    async replaceAlerts({ commit }, alerts) {
      commit('alerts', { alerts, clear: true });
    },
    async clearAlerts({ commit }) {
      commit('alerts', { alerts: [], clear: true });
    },
  },
};
