import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import auth from '@feathersjs/authentication-client';
import io from 'socket.io-client';
import { iff, discard } from 'feathers-hooks-common';
import feathersVuex from 'feathers-vuex';
import Vue from 'vue';
import { ReasonPhrases, StatusCodes } from 'http-status-codes';
import emitter from '@/lib/emitter';
import { APOLLO_STORAGE_KEY } from './constants';

const socket = io(process.env.VUE_APP_API_URL || 'http://localhost:3030');

// Set up feathers client

const timeout = Number.parseInt(process.env.VUE_APP_FEATHERS_TIMEOUT || '', 10);

const attachAgencyPropertyOrganizationIds = context => {
  if (context?.arguments?.[0]?.query?.$limit === 0) {
    return context;
  }

  const propertyId =
    context.service.FeathersVuexModel?.store.getters[
      'properties/selectedPropertyId'
    ];

  const agencyId =
    context.service.FeathersVuexModel?.store.getters['auth/selectedAgencyId'];

  const organizationId =
    context.service.FeathersVuexModel?.store.getters[
      'auth/selectedOrganizationId'
    ];

  // spreading the query last to enable custom filtering that overrides default params
  context.params.query = {
    ...(propertyId && { $propertyId: propertyId }),
    ...(agencyId && { $agencyId: agencyId }),
    ...(organizationId && { $organizationId: organizationId }),
    ...(context.params.query || {}),
  };

  return context;
};

const checkIfAccessTokenIsStored = async context => {
  if (
    context.path !== 'authentication' &&
    context.path !== 'authentication-management' &&
    context.path !== 'signups' &&
    context.path !== 'organization-plans' &&
    context.path !== 'stripe-checkout-session' &&
    window.localStorage.getItem(APOLLO_STORAGE_KEY) === null
  ) {
    context.app.services.properties.FeathersVuexModel.store.dispatch(
      'auth/customLogout',
    );
    throw new Error('Access token not found');
  }
  return context;
};

const feathersClient = feathers()
  .configure(
    socketio(socket, {
      timeout: !Number.isNaN(timeout) ? timeout : 10000,
    }),
  )
  .configure(
    auth({
      storage: window.localStorage,
      storageKey: APOLLO_STORAGE_KEY,
    }),
  )
  .hooks({
    before: {
      all: [
        checkIfAccessTokenIsStored,
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp'),
        ),
        attachAgencyPropertyOrganizationIds,
      ],
      find: [],
      get: [],
    },
    after: {
      all: [
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp'),
        ),
      ],
    },
    error(context) {
      if (
        context.path !== 'authentication' &&
        context.path !== 'authentication-management' &&
        window.localStorage.getItem(APOLLO_STORAGE_KEY) === null &&
        context.error.code === StatusCodes.UNAUTHORIZED
      ) {
        context.app.logout();
        Vue.router.push({ name: 'login' });
      }

      if (context.error.code === StatusCodes.TOO_MANY_REQUESTS) {
        emitter.emit(ReasonPhrases.TOO_MANY_REQUESTS, { error: context.error });
      }

      return context;
    },
  });

export default feathersClient;

// Set up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } =
  feathersVuex(feathersClient, {
    serverAlias: 'api',
    whitelist: ['$regex', '$options', '$like', '$iLike'],
    paramsForServer: [
      '$agencyId',
      '$association',
      '$associationArgs',
      '$eager',
      '$eager',
      '$modify',
      '$organizationId',
      '$propertyEnvironmentId',
      '$propertyId',
      '$scoped',
      ['$limit', -1],
    ],
  });

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex };
