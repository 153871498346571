/**
 * Example
 *  v-btn(
 *     @click="$store.dispatch('notifications/addNotifications', [{text: 'Text goes here', top: true, type: 'error', visible: true}])"
 *   )
 */
export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  getters: {
    notifications: state => state.notifications,
  },
  mutations: {
    notifications(state, { notifications, clear }) {
      state.notifications = clear
        ? notifications
        : [...state.notifications, ...notifications];
    },
  },
  actions: {
    async addNotifications({ commit }, notifications) {
      commit('notifications', { notifications, clear: false });
    },
    async replaceNotifications({ commit }, notifications) {
      commit('notifications', { notifications, clear: true });
    },
    async clearNotifications({ commit }) {
      commit('notifications', { notifications: [], clear: true });
    },
  },
};
