import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';
class MigrationVariableElements extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'MigrationVariableElements';
}
const servicePath = 'migration-variable-elements';
const servicePlugin = makeServicePlugin({
  Model: MigrationVariableElements,
  service: feathersClient.service(servicePath),
  servicePath,
});
// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});
export default servicePlugin;
