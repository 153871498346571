import Vue from 'vue';
import logger from '@/lib/logger';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

export default () => {
  Vue.filter('log', value => {
    logger.info(value);
    return value;
  });
  Vue.filter('date', value => {
    const date = new Date(value);
    if (!isValid(date)) return '';

    return format(date, 'MM/dd/yyyy h:mma');
  });
  Vue.filter('dateOnly', value => {
    const date = new Date(value);
    if (!isValid(date)) return '';

    return format(date, 'yyyy-MM-dd');
  });
  Vue.filter('timeOnly', value => {
    const date = new Date(value);
    if (!isValid(date)) return '';

    return format(date, 'h:mma');
  });
};
