import Vue from 'vue';
import Router from 'vue-router';
import guards from 'vue-router-multiguard';

import store from '@/stores';
import { EcosystemCodes } from '@/lib/ecosystems.enum';

import { dataLayerTracking, setPageCategory } from '@/lib/populateDataLayer';
import { getPageInfo } from '@/lib/getPageInfo';
import { AgencyRoles, OrganizationRoles } from '@/lib/roles';
import resolveProperty from './route-guards/resolve-property';
import checkPropertyStatus from './route-guards/check-property-status';
import { hasAccess } from '@/lib/hasAccess';
import { PermissionCodes, PermissionTypes } from '@/lib/permissions';
import { PropertyPlanCodes } from '@/lib/property-plans.enum';
import { AccessStatuses } from '@/lib/accessStatus.enum';

const AuthRedirect = () =>
  import(/* webpackChunkName: "authRedirect" */ '@/views/AuthRedirect');
const NotFound = () =>
  import(/* webpackChunkName: "notFound" */ '@/views/NotFound');
const Home = () => import(/* webpackChunkName: "home" */ '@/views/Home');
const Login = () => import(/* webpackChunkName: "login" */ '@/views/Login');
const Signup = () => import('@/views/Signup');
const Admin = () => import(/* webpackChunkName: "admin" */ '@/views/Admin');
const Organizations = () =>
  import(
    /* webpackChunkName: "organizations" */ '@/views/Admin/Organizations/List'
  );
const Users = () =>
  import(/* webpackChunkName: "users" */ '@/views/Admin/Users');
const EditUser = () =>
  import(/* webpackChunkName: "editUser" */ '@/views/Admin/Users/Edit');
const Agencies = () =>
  import(/* webpackChunkName: "agencies" */ '@/views/Admin/Agencies/List');
const EditAgency = () => import('@/views/Admin/Agencies/List/Edit');
const AgencyUsers = () =>
  import(/* webpackChunkName: "agencyUsers" */ '@/views/Admin/Agencies/Users');
const NewAgencyUser = () =>
  import(
    /* webpackChunkName: "newAgencyUser" */ '@/views/Admin/Agencies/Users/New'
  );
const OauthCompleted = () => import('@/views/OauthCompleted');
const OrganizationsUsers = () =>
  import(
    /* webpackChunkName: "organizationsUsers" */ '@/views/Admin/Organizations/Users'
  );
/***
 * Property Creation
 */
const Properties = () =>
  import(/* webpackChunkName: "properties" */ '@/views/Properties/List');
const Property = () => import('@/views/Properties/View');
const PropertyCreate = () => import('@/views/Properties/Create');
const PropertyEdit = () => import('@/views/Properties/Edit');
const Step1 = () => import('@/views/Properties/Create/Steps/Step1');
const Step2 = () => import('@/views/Properties/Create/Steps/Step2');
const Step3 = () => import('@/views/Properties/Create/Steps/Step3');
const Step4 = () => import('@/views/Properties/Create/Steps/Step4');
const Step5 = () => import('@/views/Properties/Create/Steps/Step5');

const PropertyDashboards = () =>
  import('@/views/Properties/View/Deploy/Dashboards');
const PropertyCalculatedMetrics = () =>
  import('@/views/Properties/View/Deploy/CalculatedMetrics');
const PropertyAssignEcosystemKeys = () =>
  import('@/views/Properties/View/Design/AssignEcosystemKeys');
const PropertyBusinessRequirements = () =>
  import('@/views/Properties/View/Design/BusinessRequirements/index.vue');
const BusinessRequirementDetails = () =>
  import(
    '@/views/Properties/View/Design/BusinessRequirements/BusinessRequirementDetails'
  );

const EventAttributes = () =>
  import('@/views/Properties/View/Design/EventAttributes/index.vue');
const CreateAndEditEvent = () =>
  import(
    '@/views/Properties/View/Design/EventAttributes/CreateAndEditEvent/index.vue'
  );
const CreateAndEditAttribute = () =>
  import(
    '@/views/Properties/View/Design/EventAttributes/CreateAndEditAttribute'
  );
const EventDetails = () =>
  import('@/views/Properties/View/Design/EventAttributes/EventDetails');
const CreateAndEditVariable = () =>
  import(
    '@/views/Properties/View/Design/EventAttributes/CreateAndEditVariable'
  );
const TrackingItemDetails = () =>
  import(
    '@/views/Properties/View/Design/EventAttributes/TrackingItemDetails/index.vue'
  );
const TrackingItemEdit = () =>
  import(
    '@/views/Properties/View/Design/EventAttributes/TrackingItemEdit/index.vue'
  );

const PropertyBusinessUser = () =>
  import('@/views/Properties/View/Act/BusinessUser');
const PropertyTechnicalUser = () =>
  import('@/views/Properties/View/Act/TechnicalUser');
const PropertyDataLayer = () =>
  import('@/views/Properties/View/Document/DataLayer');
const PropertyDataEnrichment = () =>
  import('@/views/Properties/View/Document/DataEnrichment');
const PropertyDocumentation = () =>
  import('@/views/Properties/View/Document/Documentation');
const PropertyMapExistingImplementation = () =>
  import('@/views/Properties/View/Design/MapExistingImplementation');
const PropertyVisualSDR = () =>
  import('@/views/Properties/View/Document/VisualSDR/index.vue');
const CreateAndEditBusinessRequirement = () =>
  import(
    '@/views/Properties/View/Design/BusinessRequirements/CreateAndEditBusinessRequirement'
  );
const AddEventAttributeToBR = () =>
  import(
    '@/views/Properties/View/Design/EventAttributes/AddEventAttributeToBR'
  );

const BusinessRequirementsTemplate = () =>
  import(
    '@/views/Properties/View/Design/BusinessRequirements/BusinessRequirementsTemplate'
  );
const ForgotPassword = () => import('@/views/Users/ForgotPassword');
const VerifyResetPassword = () => import('@/views/Users/VerifyResetPassword');
const LiftAndShift = () =>
  import('@/views/Properties/View/Deploy/LiftAndShift');
const Builds = () => import('@/views/Properties/View/Deploy/Builds');
const BuildsBasic = () => import('@/views/Properties/View/Deploy/BuildsBasic');
const DeployBuild = () =>
  import('@/views/Properties/View/Deploy/Builds/Deploy');
const QATests = () => import('@/views/Properties/View/Design/QATests');
const FeatureFlags = () => import('@/views/Admin/FeatureFlags');
const DataSources = () => import('@/views/Properties/View/Design/DataSources');

const AdminSettings = () => import('@/views/Admin/AdminSettings');
const DataImport = () => import('@/views/Admin/DataImport');
const GenerateSpreadsheet = () => import('@/views/Admin/GenerateSpreadsheet');
const AdminDeploymentsTab = () => import('@/views/Admin/AdminDeploymentsTab');
const AttributeDetails = () =>
  import('@/views/Properties/View/Design/EventAttributes/AttributeDetails');
const SelectVariablePanel = () =>
  import('@/views/Properties/View/Design/EventAttributes/SelectVariablePanel');

const SelectDataSourceConfig = () =>
  import('@/views/Properties/View/Design/DataSources/SelectDataSourceConfig');

const VariableDetails = () =>
  import('@/views/Properties/View/Design/EventAttributes/VariableDetails');

const DataSourceDetails = () =>
  import('@/views/Properties/View/Design/DataSources/DataSourceDetails');

const CreateAndEditDataSource = () =>
  import('@/views/Properties/View/Design/DataSources/CreateAndEditDataSource');

const SelectEvent = () =>
  import('@/views/Properties/View/Design/EventAttributes/SelectEvent');

const SelectAttribute = () =>
  import('@/views/Properties/View/Design/EventAttributes/SelectAttribute');

const EventForm = () => import('@/components/CustomDataDesigner/EventForm');
const SelectAttributeMEI = () =>
  import(
    '@/views/Properties/View/Design/MapExistingImplementation/SelectAttributeMEI'
  );
const CreateAttributeMEI = () =>
  import(
    '@/views/Properties/View/Design/MapExistingImplementation/CreateAttributeMEI'
  );
const SelectVariableMEI = () =>
  import(
    '@/views/Properties/View/Design/MapExistingImplementation/SelectVariableMEI'
  );
const CreateVariableMEI = () =>
  import(
    '@/views/Properties/View/Design/MapExistingImplementation/CreateVariableMEI'
  );
const SelectDataSourceConfigMEI = () =>
  import(
    '@/views/Properties/View/Design/MapExistingImplementation/SelectDataSourceConfigMEI'
  );
const CreateDataSourceConfigMEI = () =>
  import(
    '@/views/Properties/View/Design/MapExistingImplementation/CreateDataSourceConfigMEI'
  );
const EditTrackingItemMEI = () =>
  import(
    '@/views/Properties/View/Design/MapExistingImplementation/EditTrackingItemMEI'
  );
const PropertyPurchaseView = () =>
  import('@/views/Properties/Create/PropertyPurchaseView');
const PurchaseCompleted = () => import('@/views/Signup/PurchaseCompleted');
const PurchaseCancelled = () => import('@/views/Signup/PurchaseCancelled');

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);

  return originalPush.call(this, location).catch(err => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err;
    }
    // rethrow error
    return Promise.reject(err);
  });
};

Vue.use(Router);

let entryUrl = null;

const isAuthenticated = async (to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    await store.dispatch('auth/loadOrganizationsAndAgencies', {
      propertyId: to.params.id,
    });
    if (!entryUrl) {
      return next();
    }

    const url = entryUrl;
    entryUrl = null;

    return next(url);
  }

  try {
    await store.dispatch('auth/authenticate');

    await store.dispatch('auth/loadOrganizationsAndAgencies', {
      propertyId: to.params.id,
    });
    await store.dispatch('auth/loadUserPermissions');
    return next();
  } catch (err) {
    entryUrl = to.name !== 'login' ? to.fullPath : 'home';
    return next({ name: 'login' });
  }
};

const redirectToHomeIfNeeded = async (to, from, next) => {
  const propertyPlanQueryCode = to.query['property-plan'];
  const ecosystemCode = to.query['ecosystem'];
  if (Object.values(PropertyPlanCodes).includes(propertyPlanQueryCode)) {
    localStorage.setItem('propertyPlanCode', propertyPlanQueryCode);
  }

  if (Object.values(EcosystemCodes).includes(ecosystemCode)) {
    localStorage.setItem('ecosystemCode', ecosystemCode);
  }

  if (store.getters['auth/isAuthenticated']) {
    return next({ name: 'home', replace: true });
  }

  try {
    await store.dispatch('auth/authenticate');
    await store.dispatch('auth/loadUserPermissions');
    await store.dispatch('auth/loadOrganizationsAndAgencies', {
      propertyId: to.params.id,
    });
  } catch (error) {
    return next();
  }

  if (store.getters['auth/isAuthenticated']) {
    return next({ name: 'home', replace: true });
  }

  return next();
};

const redirectToPropertiesIfNeeded = async (to, from, next) => {
  const haveOrganizations = store.getters['auth/haveOrganizations'];
  if (!haveOrganizations) {
    await store.dispatch('auth/loadOrganizationsAndAgencies', {
      propertyId: to.params.id,
    });
  }

  const haveOrganizationsAfterUpdate = store.getters['auth/haveOrganizations'];
  if (haveOrganizationsAfterUpdate) {
    next({ name: 'properties' });
  }

  return next();
};

const isAdmin = async (to, from, next) => {
  if (!store.getters['auth/user']?.isAdmin) {
    return next({ name: '404' });
  }

  return next();
};

const isOrgEnabled = async (to, from, next) => {
  if (
    store.getters['auth/selectedOrganization']?.accessStatus ===
    AccessStatuses.disabled
  ) {
    return next({ name: '404' });
  }

  return next();
};

const isAdminOrOrgAdminOrAgencyAdmin = async (to, from, next) => {
  const isSystemAdmin = store.getters['auth/isSystemAdmin'];
  const isOrganizationAdmin = store.getters['auth/isOrganizationAdmin'];
  const isAgencyAdmin = store.getters['auth/isAgencyAdmin'];

  if (!isSystemAdmin && !isOrganizationAdmin && !isAgencyAdmin) {
    return next({ name: '404' });
  }

  return next();
};

/** This function checks if current selected property has map_existing_implementation_in_progress setup status
  If so it prevents certain routes to being accessed
 */
const isNotReadonlyMode = async (to, from, next) => {
  const propertyId = to.params.id;
  if (store.getters['properties/get'](propertyId)) {
    const property = store.getters['properties/get'](propertyId);
    if (
      property?.propertySetupStatus ===
      'map_existing_implementation_in_progress'
    ) {
      return next({ name: '404' });
    }
  }

  /** If we don't find property in store we dispatch a get method than we check the property setup status*/
  try {
    await store.dispatch('properties/get', [
      propertyId,
      {
        query: {
          $association: 'defaultProperties',
        },
      },
    ]);
  } catch (error) {
    return next();
  }

  if (store.getters['properties/get'](propertyId)) {
    const property = store.getters['properties/get'](propertyId);
    if (
      property?.propertySetupStatus ===
      'map_existing_implementation_in_progress'
    ) {
      return next({ name: '404' });
    }
  }
  return next();
};

const isAdminOrAgencyAdmin = async (to, from, next) => {
  const isSystemAdmin = store.getters['auth/isSystemAdmin'];
  const isAgencyAdmin = store.getters['auth/isAgencyAdmin'];

  if (!isSystemAdmin && !isAgencyAdmin) {
    return next({ name: '404' });
  }

  return next();
};

const isOrgUser = async (to, from, next) => {
  const user = store.getters['auth/user'];

  const { data: orgUser } = store.getters['organization-users/find']({
    query: {
      $association: 'loadOrganizationUsers',
      $associationArgs: {
        userId: user.id,
      },
    },
  });

  if (!user?.isAdmin && !orgUser?.length) {
    return next({ name: '404' });
  }

  return next();
};

const isGoogleImplementation = async (to, from, next) => {
  const propertyId = to.params.id;

  if (store.getters['properties/get'](propertyId)) {
    const propertyEcosystemCode = await store.getters['properties/get'](
      propertyId,
    )?.propertyEcosystems?.[0]?.ecosystem?.code;

    if (
      propertyEcosystemCode &&
      propertyEcosystemCode === EcosystemCodes.GoogleTagManager
    ) {
      return next({ name: '404' });
    }
  }

  /** If we don't find property in store we dispatch a get method than we check the property setup status*/
  try {
    await store.dispatch('properties/get', [
      propertyId,
      {
        query: {
          $association: 'defaultProperties',
        },
      },
    ]);
  } catch (error) {
    return next();
  }

  if (store.getters['properties/get'](propertyId)) {
    const propertyEcosystemCode = await store.getters['properties/get'](
      propertyId,
    )?.propertyEcosystems?.[0]?.ecosystem?.code;

    if (
      propertyEcosystemCode &&
      propertyEcosystemCode === EcosystemCodes.GoogleTagManager
    ) {
      return next({ name: '404' });
    }
  }

  return next();
};

const canAccessCustomDataDesigner = async (to, from, next) => {
  const selectedOrganizationId = store.getters['auth/selectedOrganizationId'];
  const selectedAgencyId = store.getters['auth/selectedAgencyId'];

  const isOrganizationAdminOrArchitect = store.getters[
    'auth/haveRoleInOrganization'
  ](selectedOrganizationId, [
    OrganizationRoles.Admin,
    OrganizationRoles.Architect,
  ]);

  const isAgencyAdminOrArchitect = store.getters['auth/haveRoleInAgency'](
    selectedAgencyId,
    [AgencyRoles.Admin, AgencyRoles.Architect],
  );

  const isSystemAdmin = store.getters['auth/isSystemAdmin'];

  if (isOrganizationAdminOrArchitect || isAgencyAdminOrArchitect) {
    return next();
  }

  next({ name: '404' });
};

const canCreateCustomDataInMEI = async (to, from, next) => {
  const selectedOrganizationId = store.getters['auth/selectedOrganizationId'];
  const selectedAgencyId = store.getters['auth/selectedAgencyId'];

  const isOrganizationAdminOrArchitect = store.getters[
    'auth/haveRoleInOrganization'
  ](selectedOrganizationId, [
    OrganizationRoles.Admin,
    OrganizationRoles.Architect,
  ]);

  const isAgencyAdminOrArchitect = store.getters['auth/haveRoleInAgency'](
    selectedAgencyId,
    [AgencyRoles.Admin, AgencyRoles.Architect],
  );

  if (isOrganizationAdminOrArchitect || isAgencyAdminOrArchitect) {
    return next();
  }

  next({ name: '404' });
};

const externalEntry = async (to, from, next) =>
  to.query.external ? next() : next(from);

const redirectToDefaultTab = (to, from, next) => {
  if (to.name !== 'property') {
    return next();
  }
  const name = localStorage.getItem('BusersChoice')
    ? 'property.design.eventAttributes'
    : 'property.design.businessRequirements';

  return next({ name, params: to?.params });
};

const routes = [
  //
  // General:
  //
  {
    name: '404',
    path: '/404',
    component: NotFound,
  },
  {
    path: '*',
    redirect: '404',
  },
  {
    name: 'AuthRedirect',
    path: '/auth-redirect',
    component: AuthRedirect,
  },

  //
  // Pages:
  //
  {
    path: '/login/',
    name: 'login',
    component: Login,
    meta: {
      noAuth: true,
    },
    beforeEnter: redirectToHomeIfNeeded,
  },
  // {
  //   path: '/create-your-account',
  //   name: 'Signup',
  //   component: Signup,
  //   meta: {
  //     noAuth: true,
  //   },
  //   beforeEnter: redirectToHomeIfNeeded,
  //   children: [
  //     {
  //       path: ':organizationId/purchase-completed',
  //       name: 'Signup.purchaseCompleted',
  //       components: { purchaseCompleted: PurchaseCompleted },
  //       meta: {
  //         noAuth: true,
  //       },
  //       beforeEnter: redirectToHomeIfNeeded,
  //     },
  //     {
  //       path: ':organizationId/purchase-cancelled',
  //       name: 'Signup.purchaseCancelled',
  //       components: { purchaseCancelled: PurchaseCancelled },
  //       meta: {
  //         noAuth: true,
  //       },
  //       beforeEnter: redirectToHomeIfNeeded,
  //     },
  //   ],
  // },
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: guards([isAuthenticated, redirectToPropertiesIfNeeded]),
  },
  {
    path: '/oauth-completed',
    name: 'oauth',
    component: OauthCompleted,
    beforeEnter: externalEntry,
  },
  {
    path: '/properties',
    name: 'properties',
    component: Properties,
    beforeEnter: guards([isAuthenticated, isOrgUser]),
    children: [
      {
        path: 'new',
        name: 'properties.new.step1',
        components: {
          new: PropertyCreate,
        },
        beforeEnter: guards([
          isAuthenticated,
          isAdminOrOrgAdminOrAgencyAdmin,
          isOrgEnabled,
        ]),
      },
      {
        path: 'new/:id/steps/step2',
        name: 'properties.new.step2',
        components: {
          step2: Step2,
        },
        beforeEnter: guards([isAuthenticated, isAdminOrOrgAdminOrAgencyAdmin]),
      },
      {
        path: 'new/:id/steps/step3',
        name: 'properties.new.step3',
        components: {
          step3: Step3,
        },
        beforeEnter: guards([
          isAuthenticated,
          isAdminOrOrgAdminOrAgencyAdmin,
          resolveProperty,
        ]),
      },
      {
        path: 'new/:id/steps/step4',
        name: 'properties.new.step4',
        components: {
          step4: Step4,
        },
        beforeEnter: guards([isAuthenticated, isAdminOrOrgAdminOrAgencyAdmin]),
      },
      {
        path: 'new/:id/steps/step5',
        name: 'properties.new.step5',
        components: {
          step5: Step5,
        },
        beforeEnter: guards([isAuthenticated, isAdminOrOrgAdminOrAgencyAdmin]),
      },
      {
        path: 'new/:id',
        name: 'properties.created.step1',
        components: {
          create: PropertyCreate,
        },
        beforeEnter: guards([
          isAuthenticated,
          isAdminOrOrgAdminOrAgencyAdmin,
          resolveProperty,
        ]),
      },
      {
        path: 'payment/:id',
        name: 'properties.payment',
        components: {
          payment: PropertyPurchaseView,
        },
        beforeEnter: guards([isAuthenticated, isAdminOrOrgAdminOrAgencyAdmin]),
      },
    ],
  },
  {
    path: '/properties/:id',
    name: 'property',
    component: Property,
    beforeEnter: guards([
      isAuthenticated,
      isOrgUser,
      isOrgEnabled,
      checkPropertyStatus,
      redirectToDefaultTab,
    ]),
    // redirect: {
    //   name: localStorage.getItem('BusersChoice')
    //     ? 'property.design.eventAttributes'
    //     : 'property.design.businessRequirements',
    // },
    children: [
      {
        path: 'event-attributes',
        name: 'property.design.eventAttributes',
        component: EventAttributes,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewEvents,
            },
          ],
        },
      },
      {
        path: 'business-user',
        name: 'property.act.businessUser',
        component: PropertyBusinessUser,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ManageBusinessUser,
            },
          ],
        },
      },
      {
        path: 'assign-ecosystem-keys',
        name: 'property.design.assignEcosystemKeys',
        component: PropertyAssignEcosystemKeys,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewAssignVariables,
            },
          ],
        },
      },
      {
        path: 'templates/new',
        name: 'property.design.businessRequirements.templates.new',
        component: BusinessRequirementsTemplate,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
      },
      {
        path: 'templates/:templateId',
        name: 'property.design.businessRequirements.template.edit',
        component: BusinessRequirementsTemplate,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
      },
      {
        path: 'business-requirements',
        name: 'property.design.businessRequirements',
        component: PropertyBusinessRequirements,
        beforeEnter: guards([
          isAuthenticated,
          isOrgUser,
          (...[, from, next]) => {
            if (from.query?.clear) {
              const message =
                'Returning to a Business Requirement that you attempted to edit is not allowed';
              console.error(message);
              store.dispatch('notifications/addNotifications', [
                {
                  text: message,
                  type: 'error',
                  visible: true,
                },
              ]);
              return next(from);
            }
            next();
          },
        ]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewBusinessRequirements,
            },
          ],
        },
        children: [
          // BUSINESS REQUIREMENT ROUTES
          {
            path: 'new',
            name: 'property.design.businessRequirements.br-new',
            components: { 'br-new': CreateAndEditBusinessRequirement },
            beforeEnter: guards([
              isAuthenticated,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: ':businessRequirementId',
            name: 'property.design.businessRequirements.br-details',
            components: {
              'br-details': BusinessRequirementDetails,
            },
            beforeEnter: guards([isAuthenticated, isOrgUser]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ViewBusinessRequirements,
                },
              ],
            },
          },
          {
            path: ':businessRequirementId/edit',
            name: 'property.design.businessRequirements.br-edit',
            components: {
              'br-edit': CreateAndEditBusinessRequirement,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: ':businessRequirementId/replace',
            name: 'property.design.businessRequirements.br-replace',
            sensitive: true,
            components: {
              'br-replace': CreateAndEditBusinessRequirement,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },

          // EVENT ATTRIBUTE ROUTES
          {
            path: ':businessRequirementId/edit/event-attributes',
            name: 'property.design.businessRequirements.edit.event-attributes',
            components: {
              ['event-attributes']: AddEventAttributeToBR,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              (...[, from, next]) => {
                if (from.query?.clear) {
                  const message =
                    'Returning to a Business Requirement that you replaced is not allowed';
                  console.error(message);
                  store.dispatch('notifications/addNotifications', [
                    {
                      text: message,
                      type: 'error',
                      visible: true,
                    },
                  ]);
                  return next(from);
                }
                next();
              },
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },

          // EVENT ROUTES
          {
            path: 'event/create',
            name: 'property.design.businessRequirements.event-new',
            components: {
              'event-new': CreateAndEditEvent,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'event/:eventId/edit',
            name: 'property.design.businessRequirements.event-edit',
            components: {
              'event-edit': CreateAndEditEvent,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'event/:eventId/replace',
            name: 'property.design.businessRequirements.event-replace',
            components: {
              'event-replace': CreateAndEditEvent,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'event/:eventId',
            name: 'property.design.businessRequirements.event-details',
            components: {
              'event-details': EventDetails,
            },
            beforeEnter: guards([isAuthenticated, isOrgUser]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ViewBusinessRequirements,
                },
              ],
            },
          },
          {
            path: ':businessRequirementId/selectEvent',
            name: 'property.design.businessRequirements.select-event',
            components: {
              'select-event': SelectEvent,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },

          // ATTRIBUTE ROUTES
          {
            path: 'attribute/create',
            name: 'property.design.businessRequirements.attribute-new',
            components: {
              'attribute-new': CreateAndEditAttribute,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'attribute/:attributeId/edit',
            name: 'property.design.businessRequirements.attribute-edit',
            components: {
              'attribute-edit': CreateAndEditAttribute,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'attribute/:attributeId/replace',
            name: 'property.design.businessRequirements.attribute-replace',
            components: {
              'attribute-replace': CreateAndEditAttribute,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'attribute/:attributeId',
            name: 'property.design.businessRequirements.attribute-details',
            components: {
              'attribute-details': AttributeDetails,
            },
            beforeEnter: guards([isAuthenticated, isOrgUser]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ViewBusinessRequirements,
                },
              ],
            },
          },
          {
            path: ':businessRequirementId/selectAttribute',
            name: 'property.design.businessRequirements.select-attribute',
            components: {
              'select-attribute': SelectAttribute,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },

          // DATA SOURCE ROUTES
          {
            path: 'data-source/:dataSourceConfigId',
            name: 'property.design.businessRequirements.data-source-details',
            sensitive: true,
            components: {
              'data-source-details': DataSourceDetails,
            },
            beforeEnter: guards([isAuthenticated, isOrgUser]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ViewBusinessRequirements,
                },
              ],
            },
          },
          {
            path: 'data-source/create',
            name: 'property.design.businessRequirements.datasource-new',
            sensitive: true,
            components: {
              'data-source-create': CreateAndEditDataSource,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
            ]),
            meta: {
              requiredPermissions: [
                { permissionCode: PermissionCodes.ManageCustomRecords },
              ],
            },
          },
          {
            path: 'data-source/:dataSourceConfigId/edit',
            name: 'property.design.businessRequirements.datasource-edit',
            sensitive: true,
            components: {
              'data-source-edit': CreateAndEditDataSource,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'data-source/:dataSourceConfigId/replace',
            name: 'property.design.businessRequirements.datasource-replace',
            sensitive: true,
            components: {
              'data-source-replace': CreateAndEditDataSource,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: ':businessRequirementId/selectDataSourceConfig',
            name: 'property.design.businessRequirements.selectDataSourceConfig',
            components: {
              'select-datasource': SelectDataSourceConfig,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },

          // VARIABLES ROUTES
          {
            path: 'variable/create',
            name: 'property.design.businessRequirements.variable-new',
            components: {
              'variable-new': CreateAndEditVariable,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'variable/:variableId/edit',
            name: 'property.design.businessRequirements.variable-edit',
            components: {
              'variable-edit': CreateAndEditVariable,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'variable/:variableId/replace',
            name: 'property.design.businessRequirements.variable-replace',
            components: {
              'variable-replace': CreateAndEditVariable,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: 'variable/:variableId',
            name: 'property.design.businessRequirements.variable-details',
            components: {
              'variable-details': VariableDetails,
            },
            beforeEnter: guards([isAuthenticated, isOrgUser]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ViewBusinessRequirements,
                },
              ],
            },
          },
          {
            path: ':businessRequirementId/variable-select',
            name: 'property.design.businessRequirements.variable-select',
            components: {
              'variable-select': SelectVariablePanel,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },

          // TRACKING ITEM ROUTES
          {
            path: ':businessRequirementId/tracking-item/:trackingItemId',
            name: 'property.design.businessRequirements.tracking-item-details',
            components: {
              'tracking-item-details': TrackingItemDetails,
            },
            beforeEnter: guards([isAuthenticated, isOrgUser]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ViewBusinessRequirements,
                },
              ],
            },
          },
          {
            path: ':businessRequirementId/tracking-item/:trackingItemId/edit',
            name: 'property.design.businessRequirements.tracking-item-edit',
            sensitive: true,
            components: {
              'tracking-item-edit': TrackingItemEdit,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
          {
            path: ':businessRequirementId/tracking-item/:trackingItemId/replace',
            name: 'property.design.businessRequirements.tracking-item-replace',
            sensitive: true,
            components: {
              'tracking-item-replace': TrackingItemEdit,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canAccessCustomDataDesigner,
              isNotReadonlyMode,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecords,
                },
              ],
            },
          },
        ],
      },

      {
        path: 'calculated-metrics',
        name: 'property.deploy.calculatedMetrics',
        component: PropertyCalculatedMetrics,
        beforeEnter: guards([
          isAuthenticated,
          isOrgUser,
          isNotReadonlyMode,
          isGoogleImplementation,
        ]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewCalculatedMetrics,
            },
          ],
        },
      },
      {
        path: 'dashboards',
        name: 'property.deploy.dashboards',
        component: PropertyDashboards,
        beforeEnter: guards([
          isAuthenticated,
          isOrgUser,
          isNotReadonlyMode,
          isGoogleImplementation,
        ]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewDashboards,
            },
          ],
        },
      },
      {
        path: 'map-existing-implementation',
        name: 'property.design.mapExistingImplementation',
        component: PropertyMapExistingImplementation,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewMapExistingImplementation,
            },
          ],
        },
        children: [
          // EVENT ROUTES
          {
            path: 'event/create',
            props: true,
            name: 'property.design.mapExistingImplementation.event-new',
            components: {
              'event-new': EventForm,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canCreateCustomDataInMEI,
            ]),
            meta: {
              requiredPermissions: [
                {
                  permissionType: PermissionTypes.Property,
                  permissionCode: PermissionCodes.ManageCustomRecordsInMEI,
                },
              ],
            },
          },

          // ATTRIBUTE ROUTES
          {
            path: 'attribute/create',
            name: 'property.design.mapExistingImplementation.attribute-new',
            components: {
              'attribute-new': CreateAttributeMEI,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canCreateCustomDataInMEI,
            ]),
          },
          {
            path: 'attribute/select',
            name: 'property.design.mapExistingImplementation.attribute-select',
            components: {
              'attribute-select': SelectAttributeMEI,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canCreateCustomDataInMEI,
            ]),
          },

          // VARIABLE ROUTES
          {
            path: 'variable/create',
            name: 'property.design.mapExistingImplementation.variable-new',
            components: {
              'variable-new': CreateVariableMEI,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canCreateCustomDataInMEI,
            ]),
          },
          {
            path: 'variable/select',
            name: 'property.design.mapExistingImplementation.variable-select',
            components: {
              'variable-select': SelectVariableMEI,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canCreateCustomDataInMEI,
            ]),
          },

          // DATA SOURCE CONFIG ROUTES
          {
            path: 'data-source/create',
            name: 'property.design.mapExistingImplementation.data-source-new',
            components: {
              'data-source-new': CreateDataSourceConfigMEI,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canCreateCustomDataInMEI,
            ]),
          },
          {
            path: 'data-source/select',
            name: 'property.design.mapExistingImplementation.data-source-select',
            components: {
              'data-source-select': SelectDataSourceConfigMEI,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canCreateCustomDataInMEI,
            ]),
          },

          // TRACKING ITEM ROUTES
          {
            path: 'tracking-item',
            name: 'property.design.mapExistingImplementation.tracking-item-edit',
            components: {
              'tracking-item-edit': EditTrackingItemMEI,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              canCreateCustomDataInMEI,
            ]),
          },
        ],
      },
      {
        path: 'qa-tests',
        name: 'property.design.qaTests',
        component: QATests,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ManageTests,
            },
          ],
        },
      },
      {
        path: 'dataLayer',
        name: 'property.document.dataLayer',
        component: PropertyDataLayer,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewDocumentation,
            },
          ],
        },
      },
      {
        path: 'dataEnrichment',
        name: 'property.document.dataEnrichment',
        component: PropertyDataEnrichment,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewDocumentation,
            },
          ],
        },
      },
      {
        path: 'documentation',
        name: 'property.document.documentation',
        component: PropertyDocumentation,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewDataDestination,
            },
          ],
        },
      },
      {
        path: 'visual-sdr',
        name: 'property.document.visualSDR',
        component: PropertyVisualSDR,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewVisualSdr,
            },
          ],
        },
      },
      {
        path: 'lift-and-shift',
        name: 'property.deploy.liftAndShift',
        component: LiftAndShift,
        beforeEnter: guards([
          isAuthenticated,
          isOrgUser,
          isNotReadonlyMode,
          isGoogleImplementation,
        ]),
      },
      {
        path: 'builds',
        name: 'property.deploy.builds',
        component: Builds,
        beforeEnter: guards([isAuthenticated, isOrgUser, isNotReadonlyMode]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewBuilds,
            },
          ],
        },
      },
      {
        path: 'builds-basic',
        name: 'property.deploy.builds-basic',
        component: BuildsBasic,
        beforeEnter: guards([isAuthenticated, isOrgUser, isNotReadonlyMode]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.GaBasicMigrateDeploy,
            },
          ],
        },
      },
      {
        path: 'builds/:buildId?',
        name: 'property.deploy.build',
        component: Builds,
        beforeEnter: guards([isAuthenticated, isOrgUser, isNotReadonlyMode]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ManageBuilds,
            },
          ],
        },
        children: [
          {
            path: 'deploy',
            name: 'property.deploy.build.deploy',
            components: {
              deploy: DeployBuild,
            },
            beforeEnter: guards([
              isAuthenticated,
              isOrgUser,
              isNotReadonlyMode,
            ]),
          },
        ],
      },
      {
        path: 'data-sources',
        name: 'property.design.data-sources',
        component: DataSources,
        beforeEnter: guards([isAuthenticated, isOrgUser]),
        meta: {
          requiredPermissions: [
            {
              permissionType: PermissionTypes.Property,
              permissionCode: PermissionCodes.ViewDataSources,
            },
          ],
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
    beforeEnter: guards([isAuthenticated, isAdmin]),
  },
  {
    path: '/admin/settings',
    name: 'admin.settings',
    component: AdminSettings,
    redirect: { name: 'admin.settings.dataImport' },
    beforeEnter: guards([isAuthenticated, isAdmin]),
    children: [
      {
        path: '/admin/settings/data-import',
        name: 'admin.settings.dataImport',
        components: {
          dataImport: DataImport,
        },
        beforeEnter: guards([isAuthenticated, isAdmin]),
      },
      {
        path: '/admin/settings/feature-flags',
        name: 'admin.settings.featureFlags',
        components: {
          featureFlags: FeatureFlags,
        },
        beforeEnter: guards([isAuthenticated, isAdmin]),
      },
      {
        path: '/admin/settings/generate-spreadsheet',
        name: 'admin.settings.generateSpreadsheet',
        components: {
          generateSpreadsheet: GenerateSpreadsheet,
        },
        beforeEnter: guards([isAuthenticated, isAdmin]),
      },
      {
        path: '/admin/settings/deployments',
        name: 'admin.settings.deployments',
        components: {
          deployments: AdminDeploymentsTab,
        },
        beforeEnter: guards([isAuthenticated, isAdmin]),
      },
    ],
  },
  {
    path: '/admin/agencies',
    name: 'admin.agencies',
    component: Agencies,
    beforeEnter: guards([isAuthenticated, isAdminOrAgencyAdmin]),
    children: [
      {
        path: '/admin/agencies/new',
        name: 'admin.agency.new',
        components: {
          new: EditAgency,
        },
        beforeEnter: guards([isAuthenticated, isAdmin]),
      },
    ],
  },
  {
    path: '/admin/agencies/:id',
    name: 'admin.agency',
    component: Agencies,
    beforeEnter: guards([isAuthenticated, isAdminOrAgencyAdmin]),
    redirect: { name: 'admin.agencies' },
    children: [
      {
        path: 'edit',
        name: 'admin.agency.edit',
        components: {
          edit: EditAgency,
        },
        beforeEnter: guards([isAuthenticated, isAdmin]),
      },
    ],
  },
  {
    path: '/admin/agencies/:id/users',
    name: 'admin.agency.users',
    component: AgencyUsers,
    beforeEnter: guards([isAuthenticated, isAdminOrAgencyAdmin]),
    children: [
      {
        path: 'new',
        name: 'admin.agency.users.new',
        components: {
          new: NewAgencyUser,
        },
        beforeEnter: guards([isAuthenticated, isAdminOrAgencyAdmin]),
      },
    ],
  },
  {
    path: '/admin/users',
    name: 'admin.users',
    component: Users,
    beforeEnter: guards([isAuthenticated, isAdmin]),
    children: [
      {
        path: 'new',
        name: 'admin.users.new',
        components: {
          new: EditUser,
        },
        beforeEnter: guards([isAuthenticated, isAdmin]),
      },
    ],
  },
  {
    path: '/admin/users/:id',
    name: 'admin.user',
    component: Users,
    beforeEnter: guards([isAuthenticated, isAdmin]),
    redirect: { name: 'admin.users' },
    children: [
      {
        path: 'edit',
        name: 'admin.user.edit',
        components: {
          new: EditUser,
        },
        beforeEnter: guards([isAuthenticated, isAdmin]),
      },
    ],
  },
  {
    path: '/admin/organizations',
    name: 'admin.organizations',
    component: Organizations,
    beforeEnter: guards([isAuthenticated, isAdminOrOrgAdminOrAgencyAdmin]),
  },
  {
    path: '/admin/organizations/:id/users',
    name: 'admin.organization.users',
    component: OrganizationsUsers,
    beforeEnter: guards([isAuthenticated, isAdminOrOrgAdminOrAgencyAdmin]),
  },
  {
    path: '/users/password/forgot',
    name: 'users.forgotPassword',
    component: ForgotPassword,
    beforeEnter: redirectToHomeIfNeeded,
    meta: {
      noAuth: true,
    },
  },
  {
    path: '/users/password/verify-reset',
    name: 'users.verifyResetPassword',
    component: VerifyResetPassword,
    meta: {
      noAuth: false,
    },
  },
];

const checkAccess = to => {
  const requiredPermissions = to.meta.requiredPermissions;
  if (requiredPermissions) {
    let accessible = true;
    requiredPermissions.forEach(p => {
      if (!hasAccess(p.permissionCode, p.permissionType)) {
        accessible = false;
      }
    });
    return accessible;
  }

  return true;
};

const router = new Router({
  mode: 'history',
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (store.getters['notifications/notifications']?.length) {
    store.dispatch('notifications/clearNotifications');
  }

  /* 'beforeEach' is called before 'beforeEnter' guards this handles the case where user was logged in but refreshes the page */
  if (!store.getters['auth/isAuthenticated']) {
    try {
      await store.dispatch('auth/authenticate');
      await store.dispatch('auth/loadOrganizationsAndAgencies', {
        propertyId: to.params.id,
      });
      await store.dispatch('auth/loadUserPermissions');
    } catch (err) {
      // handle authentication in route guard if necessary
    }
  }

  if (to.params.id && to.path.startsWith('/properties/')) {
    // property settings drawer doesn't have a route
    // we attach a query to the url instead
    await store.dispatch('properties/selectPropertyId', to.params.id);
    if (
      !!to.query.edit &&
      !hasAccess(
        PermissionCodes.ManagePropertySettings,
        PermissionTypes.Property,
        to.params.id,
      )
    ) {
      store.dispatch('properties/clearSelection');
      return next({ name: '404' });
    }
  }

  // Check if user has access to this route based on feature permissions
  if (!checkAccess(to)) {
    return next({ name: '404' });
  }

  next();
});

router.afterEach(to => {
  // Data layer tracking
  const pageInfo = getPageInfo(to.name);

  if (to.params.id && to.path.startsWith('/properties/')) {
    // property settings drawer doesn't have a route
    // we attach a query to the url instead
    if (!!to.query.edit) {
      dataLayerTracking(to.params.id, {
        category: setPageCategory(to.name),
        name: 'Property Settings',
      });
    } else {
      dataLayerTracking(to.params.id, pageInfo);
    }
  } else {
    store.dispatch('properties/clearSelection');

    if (!to.path.startsWith('/admin/settings/')) {
      dataLayerTracking('', pageInfo);
    }
  }
});

export default router;
