<template lang="pug">
v-app
  help-icon
  #intercom-icon
  #side-dialog-container
  app-header(
    :agencies='agencies'
    :isAgencyAdmin='isAgencyAdmin'
    :isAuthenticated='isAuthenticated'
    :isOrganizationAdmin='isOrganizationAdmin'
    :isSelectedProperty='isSelectedProperty'
    :isSystemAdmin='isSystemAdmin'
    :menuItems='menuItems'
    :organizations='filteredOrganizations'
    :property='selectedProperty'
    :selectedAgencyId='selectedAgencyId'
    :selectedOrganizationId='selectedOrganizationId'
    :user='user'
    @agencyChanged='onAgencyChange'
    @organizationChanged='onOrganizationChange'
    @propertySettingsClicked='propertySettingsClicked'
  )
  property-navigation(
    :disabled='!isSelectedProperty'
    :isAuthenticated='isAuthenticated'
    :propertySetupStatus='propertyStatus'
    :selectedTab='selectedTab'
    :sideBarItems='sideBarItems'
    v-if='isAuthenticated'
  )
  app-content
    app-container.pa-10(fluid)
      v-dialog(
        persistent
        v-model='isUIDisabled'
        width='600'
      )
        v-card(height='200')
          v-card-title.justify-center.text-h2.text-uppercase.cyan--text.font-weight-medium {{ $t('Scheduled Maintenance') }}
          v-card-text
            .modal-icon
              span
                i.fa-solid.fa-screwdriver-wrench.fa-2xl
            .modal-text
              p Apollo is currently down for scheduled maintenance. This won't take long, so please check back and refresh your browser in a few minutes.
              p Thank you for your patience.
      v-alert(
        :color='item.type'
        :icon='item.icon || "fa-info-circle"'
        :key='i + "alert"'
        close-text='Close'
        dismissible
        v-for='(item, i) in alerts'
        v-model='item.visible'
      ) {{ item.text }}
      v-snackbar(
        :bottom='item.bottom'
        :color='item.type'
        :key='i + "notification"'
        :left='item.left'
        :right='item.right'
        :timeout='item.timeout || 5000'
        :top='item.top'
        v-for='(item, i) in notifications'
        v-model='item.visible'
      ) {{ item.text }}
        template(v-slot:action='{ attrs }')
          v-btn(
            @click='item.visible = false'
            icon
            v-bind='attrs'
          )
            v-icon(color='white') fa-times
      router-view
  #side-dialog-container
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from '@vue/composition-api';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import emitter from '@/lib/emitter';
import { ReasonPhrases } from 'http-status-codes';
import delay from 'lodash/delay';
import { mapGetters } from 'vuex';
import { provideStore } from '@/composables/use-store';
import logger from '@/lib/logger';
import themes from '@/config/themes/searchdiscovery';
import { AppContent, AppContainer } from './styles';
import PropertyNavigation from '../PropertyNavigation';
import Header from '../Header';
import IntercomIcon from '../IntercomIcon';
import HelpIcon from '../HelpIcon';
import { dataLayerTracking, populateDataLayer } from '@/lib/populateDataLayer';
import { DataLayerEvents } from '@/lib/dataLayerEvents.enum';
import {
  ActTabPermissions,
  DeployTabPermissions,
  DesignTabPermissions,
  DocumentTabPermissions,
  PermissionTypes,
} from '@/lib/permissions';
import { hasAccess } from '@/lib/hasAccess';
import { PropertyPlanCodes } from '@/lib/property-plans.enum';
import { useFind } from 'feathers-vuex';

const {
  useState: authState,
  useGetters: authGetters,
  useActions: authActions,
} = createNamespacedHelpers('auth');

export default defineComponent({
  name: 'Layout',
  components: {
    'app-header': Header,
    AppContent,
    AppContainer,
    PropertyNavigation,
    IntercomIcon,
    HelpIcon,
  },

  setup(props, { root: { $FeathersVuex, $store, $i18n, $router, $route } }) {
    provideStore($store);

    const alerts = computed(() => $store.getters['alerts/alerts']);
    const notifications = computed(
      () => $store.getters['notifications/notifications'],
    );

    const { selectedOrganizationId, selectedAgencyId } = authState([
      'selectedOrganizationId',
      'selectedAgencyId',
    ]);

    const {
      userAgencies: agencies,
      isAgencyAdmin,
      isAuthenticated,
      isOrganizationAdmin,
      isSystemAdmin,
      userOrganizations: organizations,
      user,
    } = authGetters([
      'userAgencies',
      'isAgencyAdmin',
      'isAuthenticated',
      'isOrganizationAdmin',
      'isSystemAdmin',
      'userOrganizations',
      'user',
    ]);

    const { authenticate, setSelectedOrganization, setSelectedAgency } =
      authActions([
        'authenticate',
        'setSelectedOrganization',
        'setSelectedAgency',
      ]);

    const collator = new Intl.Collator('en', {
      numeric: true,
      sensitivity: 'base',
    });

    const sortOrganizations = () =>
      organizations.value.sort((a, b) => collator.compare(a.name, b.name));

    sortOrganizations();

    watch(
      () => organizations.value,
      () => sortOrganizations(),
    );

    const filteredOrganizations = computed(() => {
      if (!selectedAgencyId.value) {
        return organizations.value;
      }

      return organizations.value.filter(
        organization =>
          String(organization.agencyId) === String(selectedAgencyId.value),
      );
    });

    authenticate().catch(e => {
      logger.debug(e);
    });

    const menuItems = computed(() => [
      {
        title: $i18n.t('Properties'),
        to: { name: 'properties' },
        icon: 'far fa-planet-ringed',
        visible: !!organizations.value.length,
      },
      {
        title: $i18n.t('Organizations'),
        to: { name: 'admin.organizations' },
        icon: 'far fa-globe',
        visible:
          isSystemAdmin.value ||
          isAgencyAdmin.value ||
          isOrganizationAdmin.value,
      },
      {
        title: $i18n.t('Agencies'),
        to: { name: 'admin.agencies' },
        icon: 'far fa-bullseye',
        visible: isSystemAdmin.value || isAgencyAdmin.value,
      },
      {
        title: $i18n.t('Users'),
        to: { name: 'admin.users' },
        icon: 'far fa-users',
        visible: isSystemAdmin.value,
      },
      {
        title: $i18n.t('Admin'),
        to: { name: 'admin.settings' },
        icon: 'far fa-user-cog',
        visible: isSystemAdmin.value,
      },
      {
        title: $i18n.t('Logout'),
        click: () => {
          $store.dispatch('auth/customLogout');
        },
        icon: 'far fa-arrow-left',
        visible: true,
      },
    ]);

    const accessTabPermissionMap = {
      design: DesignTabPermissions,
      document: DocumentTabPermissions,
      deploy: DeployTabPermissions,
      act: ActTabPermissions,
    };

    const allSideBarItems = ref([
      {
        title: $i18n.t('Design'),
        to: { name: 'property.design.businessRequirements' },
        icon: 'fal fa-pencil-ruler',
        tab: 0,
        key: 'design',
      },
      {
        title: $i18n.t('Document'),
        to: { name: 'property.document.dataLayer' },
        icon: 'fal fa-file-signature',
        tab: 1,
        key: 'document',
      },
      {
        title: $i18n.t('Deploy'),
        to: { name: 'property.deploy.builds' },
        icon: 'fal fa-rocket',
        tab: 2,
        key: 'deploy',
      },
      {
        title: $i18n.t('Act'),
        to: { name: 'property.act.businessUser' },
        icon: 'fal fa-user-astronaut',
        tab: 3,
        key: 'act',
      },
    ]);

    const changeToBuildsBasicRoute = (items, code) =>
      items.map(item => {
        if (item.key === 'deploy') {
          item.to =
            code === PropertyPlanCodes.Basic
              ? { name: 'property.deploy.builds-basic' }
              : { name: 'property.deploy.builds' };
        }
        return item;
      });

    const selectedProperty = ref(null);

    watch(
      () => $router.history?.current?.params?.id,
      async newValue => {
        const [property] = await $store.dispatch('properties/find', {
          query: {
            id: newValue,
            $limit: -1,
            $association: 'defaultProperties',
          },
        });
        selectedProperty.value = property;
        allSideBarItems.value = changeToBuildsBasicRoute(
          allSideBarItems.value,
          property?.propertyPlan?.code,
        );
      },
    );

    const propertyStatus = computed(
      () => selectedProperty.value?.propertySetupStatus,
    );

    const sideBarItems = computed(() =>
      allSideBarItems.value.map(item => {
        item.hasAccess =
          accessTabPermissionMap[item.key] &&
          accessTabPermissionMap[item.key].some(code =>
            hasAccess(
              code,
              PermissionTypes.Property,
              selectedProperty.value?.id,
            ),
          );
        return item;
      }),
    );

    const onOrganizationChange = organizationId =>
      setSelectedOrganization(organizationId);

    const onAgencyChange = agencyId => setSelectedAgency(agencyId);

    const propertySettingsClicked = () => {
      const query = Object.assign({}, $route.query);

      query.edit = true;

      $router.push({ query });
    };

    const onTooManyRequestsError = () => {
      $store.dispatch('notifications/addNotifications', [
        {
          text: $i18n.t(
            `You've reached the maximum allowed limit of requests provided by the connection. Please try again in a few minutes.`,
          ),
          type: 'error',
          visible: true,
        },
      ]);

      populateDataLayer(DataLayerEvents.ErrorMessagePresented, {
        errorCode: `You've reached the maximum allowed limit of requests provided by the connection. Please try again in a few minutes.`,
        errorType: 'Too Many Requests',
      });
    };

    const { AdminSettings } = $FeathersVuex.api;

    const { items: adminSettings, isPending: isAdminSettingsPending } = useFind(
      {
        model: AdminSettings,
        params: computed(() => {
          if (!user.value) {
            return null;
          }
          return {
            paginate: false,
            query: {
              $limit: -1,
            },
          };
        }),
      },
    );

    const isUIDisabled = computed(
      () =>
        adminSettings.value?.[0]?.active &&
        $router.history.current.name !== 'admin.settings.deployments' &&
        $router.history.ready,
    );

    onMounted(() => {
      emitter.on(ReasonPhrases.TOO_MANY_REQUESTS, onTooManyRequestsError);
    });

    onUnmounted(() => {
      emitter.off(ReasonPhrases.TOO_MANY_REQUESTS, onTooManyRequestsError);
    });

    return {
      selectedProperty,
      propertyStatus,
      agencies,
      alerts,
      filteredOrganizations,
      isAgencyAdmin,
      isAuthenticated,
      isOrganizationAdmin,
      isSystemAdmin,
      menuItems,
      sideBarItems,
      notifications,
      onAgencyChange,
      onOrganizationChange,
      selectedAgencyId,
      selectedOrganizationId,
      themes,
      user,
      propertySettingsClicked,
      isUIDisabled,
    };
  },
  computed: {
    ...mapGetters('organization-users', {
      findOrganizationUserInStore: 'find',
    }),

    isSelectedProperty() {
      return (
        Object.keys(this.$route.params).length !== 0 &&
        this.$route.name.startsWith('property')
      );
    },

    selectedTab() {
      const routerName = this.$router.currentRoute.name;

      if (routerName?.startsWith('property.design')) {
        return 0;
      }

      if (routerName?.startsWith('property.document')) {
        return 1;
      }

      if (routerName?.startsWith('property.deploy')) {
        return 2;
      }

      if (routerName?.startsWith('property.act')) {
        return 3;
      }

      return null;
    },

    loading() {
      return (
        this.$store.state['organization-users']?.isFindPending ||
        this.$store.state.organizations?.isFindPending
      );
    },
  },

  updated() {
    if (this.alerts.length > 0) {
      this.alerts.forEach(element => {
        if (element?.isTriggeredDelay) {
          return;
        }
        delay(() => {
          element.visible = false;
          element.isTriggeredDelay = true;
        }, element.timeout || 8000);
      });
    }
  },
});
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 70px !important;
}
.modal-icon {
  float: left;
  width: 8%;
  vertical-align: middle;
  padding-top: 3%;
}
.modal-text {
  float: left;
  width: 85%;
  text-align: left;
  vertical-align: top;
}
</style>
