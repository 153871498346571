import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class Ecosystems extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Ecosystems';

  // Define default properties here
  static instanceDefaults() {
    return {
      get name() {
        if (!this.tagManager?.name || !this.dataDestination?.name)
          return 'Associations missing';
        return `${this.tagManager.name}/${this.dataDestination.name}`;
      },
      description: '',
    };
  }

  static setupInstance(data, { models }) {
    if (data.ecosystems) {
      data.ecosystems = data.ecosystems.map(
        ecosystem => new models.api.Ecosystems(ecosystem),
      );
    }
    if (data.events) {
      data.events = data.events.map(event => new models.api.Events(event));
    }
    return data;
  }
}

export const schema = {
  type: 'object',
  properties: {
    id: {
      type: 'string',
      'x-display': 'hidden',
    },
    name: {
      type: 'string',
      description: 'Enter the name',
      title: 'Name',
    },
    description: {
      type: 'string',
      title: 'Description',
      'x-display': 'textarea',
    },
  },
};

const servicePath = 'ecosystems';

const servicePlugin = makeServicePlugin({
  Model: Ecosystems,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
