import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from '@/config/feathers';

class ReportingItemTypes extends BaseModel {
  static modelName = 'ReportingItemTypes';

  // Define default properties here
  static instanceDefaults() {
    return {};
  }
}

const servicePath = 'reporting-item-types';

const servicePlugin = makeServicePlugin({
  Model: ReportingItemTypes,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
